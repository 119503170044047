/* eslint-disable no-console */
import { useCallback, useEffect, useRef } from 'react';
import { ENDPOINTS, PLAYFAB_CONFIG } from '../Constants';
import { getSingleAdPlacement, updateLocalAd } from '../redux/ads';
import { addChatMessage } from '../redux/chat';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { getItemInventory } from '../redux/inventory';
import { getMissionInventory } from '../redux/missions';
import { addNotification } from '../redux/notifications';
import { updateLocalAvatarUrl, updateLocalVirtualCurrency, updateLocalDisplayName, setNeedRefresh } from '../redux/playfab';
// import { setActivePrediction, setPredictionResult } from '../redux/predictions';
import { addRFIDTag } from '../redux/rfid';
import { updateLocalStatistic } from '../redux/statistics';
import { getStoreLoadout } from '../redux/xr_store';
import usePlayer from './usePlayer';
import { addLocalPoll, generatePollFromRealtime, getPoll } from '../redux/polls';
import { setActivePrediction, setPredictionResult } from '../redux/predictions';

let eventSource:EventSource = null;

function connect(hubUrl, topics, onMessage) {
	if (eventSource) {
		eventSource.close();
	}

	const url = new URL(hubUrl);

	for (const t in topics) {
		url.searchParams.append('topic', topics[t]);
	}

	eventSource = new EventSource(url);
	
	eventSource.onerror = (e) => {
		console.error(e);
	};

	eventSource.onmessage = (e) => {
		try {
			const data = JSON.parse(e.data);
			if (onMessage) onMessage(data);
		} catch (err) {
			console.error(err);
		}
	};

	return eventSource;
}

function debugRealtime(msg:string) {
	const colors = (msg.match(/%c/g) || []).length;
	const d = new Date();
	const date = `%c @ ${d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2, '0') + ':' + d.getSeconds().toString().padStart(2, '0')}.${d.getMilliseconds()}`;
	switch (colors) {
		case 0:
			console.log(msg + date);
			break;
		case 1:
			console.log(msg + date, 'color: #ccc;', 'color: grey;');
			break;
		case 2:
			console.log(msg + date, 'color: #ccc;', 'color: #ffbca0;', 'color: grey;');
			break;
		case 3:
			console.log(msg + date, 'color: #ccc;', 'color: #ffbca0;', 'color: teal;', 'color: grey;');
			break;
		default:
		case 4:
			console.log(msg + date, 'color: #ccc;', 'color: #ffbca0;', 'color: teal;', 'color: yellow', 'color: grey;');
			break;
	}
}

const PROFILE_INITIALISATION_RULE = '42d9debe-1e19-4de5-9f9d-286d35c021ab';

export default function useRealtime() {
	const {
		versions,
		playerStatus,
	} = useAppSelector((state) => state.realtime);

	const dispatch = useAppDispatch();

	const placements = useAppSelector((state) => state.ads);

	const {
		playerId,
	} = usePlayer();

	const onMessage = useCallback((events) => {
		for (const data of events) {
			const eventName = data.EventName;

			let msg = `%crealtime %c${eventName}`;

			switch (eventName) {
				case 'player_rule_executed':
					msg = `%crealtime %c${eventName} %c[${data.DisplayName}]`;
					if (data.RuleId === PROFILE_INITIALISATION_RULE) {
						dispatch(setNeedRefresh(true));
					}
					break;
				case 'player_triggered_action_executed_cloudscript':
					msg = `%crealtime %c${eventName} %c[${data.FunctionName}]`;
					break;
				case 'player_action_executed':
					msg = `%crealtime %c${eventName} %c[${data.ActionName}]`;
					break;
				case 'player_entered_segment':
				case 'player_left_segment':
					msg = `%crealtime %c${eventName} %c[${data.SegmentName}]`;
					break;
				case 'player_statistic_changed':
					dispatch(updateLocalStatistic({
						name: data.StatisticName,
						value: data.StatisticValue,
					}));
					msg = `%crealtime %c${eventName} %c[${data.StatisticName}: ${data.StatisticPreviousValue} > ${data.StatisticValue}]`;
					break;
				case 'player_consumed_item':
				case 'player_inventory_item_added':
					dispatch(getItemInventory());
					msg = `%crealtime %c${eventName} %c[${data.DisplayName}]`;

					if (data.ItemId.includes('m-')) {
						dispatch(getMissionInventory());
					}
					break;
				case 'player_virtual_currency_balance_changed':
					dispatch(updateLocalVirtualCurrency({ currency: data.VirtualCurrencyName, amount: data.VirtualCurrencyBalance }));
					msg = `%crealtime %c${eventName} %c[${data.VirtualCurrencyName}: ${data.VirtualCurrencyPreviousBalance} > ${data.VirtualCurrencyBalance}]`;
					// if (data.VirtualCurrencyPreviousBalance < data.VirtualCurrencyBalance) {
					// 	dispatch(addNotification(notificationGenerator({
					// 		title: 'You earned some coins!',
					// 		icon: 'trophy',
					// 		message: `Congratulations! You earned ${data.VirtualCurrencyBalance - data.VirtualCurrencyPreviousBalance} coins!`,
					// 	})));
					// }
					break;
				case 'player_displayname_changed':
					dispatch(updateLocalDisplayName(data.DisplayName));
					msg = `%crealtime %c${eventName} %c[${data.PreviousDisplayName} > ${data.DisplayName}]`;
					break;
				case 'player_changed_avatar':
					dispatch(updateLocalAvatarUrl(data.ImageUrl));
					break;
				case 'player_notification_pushed':
					dispatch(addNotification(data));
					break;
				case 'store':
				case 'player_store_cleared':
					dispatch(getStoreLoadout());
					break;
				case 'notifications':
					dispatch(addNotification(data));
					break;
				case 'player_objective_progress':
				case 'player_objective_completed':
				case 'player_mission_completed':
					dispatch(getMissionInventory());
					msg = `%crealtime %c${eventName} %c[${data.MissionId} > ${data.ObjectiveId}]`;
					break;
				case 'xr_chat_message':
					dispatch(addChatMessage(data));
					break;
				case 'rfid_login_attempt':
					msg = `%crealtime %c${eventName} %c${data.rfid} %c@${data.scannerId}`;
					dispatch(addRFIDTag(data));
					break;
				case 'match_prediction_instantiated':
					dispatch(setActivePrediction(data));
					msg = `%crealtime %c${eventName} %c[${data.PredictionData?.displayName}]`;
					break;
				case 'prediction_points_resolve':
					dispatch(setPredictionResult(data));
					msg = `%crealtime %c${eventName} %c[${JSON.stringify(data)}]`;
					return;
				case 'prediction_resolved':
					dispatch(setPredictionResult(data));
					dispatch(getItemInventory());
					msg = `%crealtime %c${eventName}`;
					break;
				case 'title_poll_instantiated':
				case 'player_poll_instantiated':
					msg = `%crealtime %c${eventName} %c${data.PollInstance} %c${data.PollQuestion}`;
					dispatch(addLocalPoll(generatePollFromRealtime(data)));
					break;
				case 'player_poll_answered':
					dispatch(getPoll(data.PollInstanceId));
					break;
				case 'player_ad_triggered':
					msg = `%crealtime %c${eventName} %c${data}`;
					dispatch(updateLocalAd({
						ad: data.Ad,
						placementId: data.AdPlacementId,
					}));
					break;
				case 'player_ad_reset':
					const ids = Object.keys(placements);
					ids.forEach((placement) => {
						dispatch(getSingleAdPlacement(placement));
					});
					break;
				case 'set_favorite_brand':
					dispatch(getItemInventory());
					break;
				case 'chat_send_message':
					dispatch(getItemInventory());
					break;
			}

			debugRealtime(msg);
		}
	}, [playerId, dispatch, placements]);

	const connection = useRef(null);

	useEffect(() => {
		const topics = [
			`playstream/${PLAYFAB_CONFIG.appId}`,
		];

		if (playerId) {
			topics.push(`playstream/${PLAYFAB_CONFIG.appId}/${playerId}`);
		}

		connection.current = connect(ENDPOINTS.REALTIME_EVENT_SOURCE, topics, onMessage);

		const interval = window.setInterval(() => {
			if (connection.current.readyState === connection.current.CLOSED) {
				connection.current = connect(ENDPOINTS.REALTIME_EVENT_SOURCE, topics, onMessage);
			}
		}, 10000);

		return () => {
			connection.current?.close();
			clearInterval(interval);
		};
	}, [playerId]);

	useEffect(() => {
		if (connection.current) {
			connection.current.onmessage = (e) => {
				try {
					const data = JSON.parse(e.data);
					if (onMessage) onMessage(data);
				} catch (err) {
					console.error(err);
				}
			};
		}
	}, [onMessage, connection.current]);

	return {
		newsVersion: versions.news,
		playerStatus,
	};
}