import { useEffect } from 'react';
import { useAppDispatch } from '../redux/config/store';
import { writePlayerEvent } from '../redux/playfab';
import useGlobalVariables from './useGlobalVariables';
import usePlayer from './usePlayer';

export default function useMatchAnalytics() {
	const { playerId } = usePlayer();
	const dispatch = useAppDispatch();

	const { RoutesNiceNames, AppSettings } = useGlobalVariables();

	useEffect(() => {
		let minutesWatched = 0;

		const interval = setInterval(() => {
			minutesWatched ++;
			dispatch(writePlayerEvent({
				name: 'streaming',
				body: {
					action: 'Progression',
					sport: AppSettings.Sport,
					time: minutesWatched,
					percentage: Math.min(minutesWatched / AppSettings.MatchLength, 1),
				},
			}));
		}, 60 * 1000);

		return () => {
			clearInterval(interval);
		};
	}, [playerId, RoutesNiceNames]);

	useEffect(() => {
		dispatch(writePlayerEvent({
			name: 'streaming',
			body: { action: 'Start Watching', sport: AppSettings.Sport },
		}));
	}, [AppSettings]);
}