import React from 'react';
import { Link } from 'react-router-dom';

export default function ShowCard({
	show,
	small = false,
}:{
	show: Show;
	small?: boolean;
}) {
	return (
		// There's only one mock show (Club Brugge vs Antwerp) for this demo, we want all show cards to point to it
		<Link to="f9bfd3c4-e9ed-5707-956c-fc93d33e5628">
			<div className={`show-card ${small ? 'small' : ''}`}>
				<img src={show.posterImg} alt="" />
				<div className="show-details">
					<div className="show-title">{show.title}</div>
				</div>
			</div>
		</Link>
	);
}
