import React, { memo, useEffect, useLayoutEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import useGlobalVariables from '../../hooks/useGlobalVariables';
import useXP from '../../hooks/useXP';
import useRewardTracks from '../reward-track/hooks/useRewardTracks';
import RewardTrack from '../reward-track/RewardTrack';
import Loading from '../ui/Loading';
import XpBar from '../ui/XPBar';
import useBackground from '../../hooks/useBackground';
import Missions from '../Missions';
import LinedTitle from '../ui/LinedTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/sharp-solid-svg-icons';
import useClickOutsideOf from '../../hooks/useClickOutsideOf';

const REWARD_TRACK_SCROLL_SPEED = 5;

function RewardTracks() {
	const { RewardTrackPage, isLoaded } = useGlobalVariables();
	const page = useRef<HTMLDivElement>(null);
	const content = useRef<HTMLDivElement>(null);

	const {
		xp,
		level,
		nextLevelXp,
		currentLevelXp,
	} = useXP();

	const {
		BackgroundImage,
	} = RewardTrackPage || {};

	const { setBackground } = useBackground();
	useEffect(() => {
		setBackground(BackgroundImage);
	});

	const tracks = useRewardTracks().filter(t => t.type.includes('journey'));

	tracks.sort((a, b) => Number(b.type.includes('premium')) - Number(a.type.includes('premium')));

	const tableScroller = useRef<HTMLDivElement>(null);

	const scrollAnimationFrame = useRef<number>();

	const endScroll = () => {
		cancelAnimationFrame(scrollAnimationFrame.current);
	};

	const scrollBtnEvents = (scrollDelta: number) => {
		const scroller = tableScroller.current;
		const maxScrollDistance = (scroller?.scrollWidth - scroller?.clientWidth) ?? 0;

		return {
			onPointerDown: () => {
				const scroll = () => {
					scroller?.scrollBy({ left: scrollDelta });

					if ((scroller?.scrollLeft > 0) && (scroller?.scrollLeft < maxScrollDistance)) {
						scrollAnimationFrame.current = requestAnimationFrame(scroll);
					}
				};
				scrollAnimationFrame.current = requestAnimationFrame(scroll);
			},
			onPointerUp: endScroll,
			onPointerLeave: endScroll,
		};
	};

	const challengesWrapper = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		const top = content.current?.getBoundingClientRect()?.top ?? 0;
		challengesWrapper.current?.style.setProperty('--challenges-wrapper-top', `${top}px`);
	}, [isLoaded, tracks]);

	useEffect(() => {
		return endScroll;
	}, []);

	useClickOutsideOf({
		targetSelector: '[data-table-scroller], [data-scroll-btn]',
		onInteractOutside: endScroll,
	});

	if (!isLoaded) return (
		<Loading isLoading={isLoaded} />
	);

	return (
		<CSSTransition nodeRef={page} key="home" timeout={1200} in={true} appear>
			<div className="page reward-track-page home" ref={page}>
				<div className="page-content">
					<CSSTransition nodeRef={content} key="home" timeout={1200} in={tracks.length > 0} appear mountOnEnter unmountOnExit>
						<div className="content reward-track-wrapper" ref={content}>
							<h2>Your Telcom Journey</h2>
							<h1>REWARDS<br/>TRACK</h1>
							<XpBar xp={xp} level={level} currentLevelXp={currentLevelXp} nextLevelXp={nextLevelXp} />
							<div className="reward-track-table-container">
								<button data-scroll-btn className="scroll-btn prev" {...scrollBtnEvents(-REWARD_TRACK_SCROLL_SPEED)}>
									<FontAwesomeIcon icon={faCaretLeft} />
								</button>
								<div className="table-scroller" data-table-scroller ref={tableScroller}>
									<table className="reward-tracks">
										<tbody>
											{tracks.map((track, i) => <RewardTrack key={track.id} trackId={track.id} showTracker={i === 0} />)}
										</tbody>
									</table>
								</div>
								<button data-scroll-btn className="scroll-btn next" {...scrollBtnEvents(REWARD_TRACK_SCROLL_SPEED)}>
									<FontAwesomeIcon icon={faCaretRight} />
								</button>
							</div>
						</div>
					</CSSTransition>
					<div className="challenges-wrapper" ref={challengesWrapper}>
						<LinedTitle className='missions-title' text='Your <strong>Weekly Challenges</strong>' caps />
						<Missions />
					</div>
				</div>
			</div>
		</CSSTransition>
	);
}

export default memo(RewardTracks);
