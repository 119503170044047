import { faLockAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { MISSIONS_TYPES } from '../Constants';
import useMissions from '../hooks/useMissions';
import CircleProgress from './ui/CircleProgress';
import { faTimer } from '@fortawesome/pro-regular-svg-icons';

function calculateProgress(objectives:IXRMissionObjective[]) {
	return objectives.reduce((acc, objective) => {
		if (!objective.PlayerStatus) return acc;

		const { completed, total } = objective.PlayerStatus.Trackers.reduce((c, tracker) => {
			return {
				completed: c.completed + parseInt(tracker.Value),
				total: c.total + parseInt(tracker.Threshold),
			};
		}, { completed: 0, total: 0 });

		return {
			completed: acc.completed + completed,
			total: acc.total + total,
		};
	}, { completed: 0, total: 0 });
}

function Mission({ data }:{ data: IXRMissionItem }) {
	const isUnlocked = data.PlayerStatus;
	const isComplete = data.PlayerStatus?.IsComplete;
	const progression = calculateProgress(data.objectives as IXRMissionObjective[]);
	const progress = isComplete ? 1 : progression.completed / (progression.total || 1);

	const classes = ['mission', 'rounded'];

	if (isUnlocked) classes.push('unlocked');

	return (
		<div className={classes.join(' ')}>
			<div className="name">{data.playfab.DisplayName}</div>
			<div className="status">
				{!isUnlocked && <div className="locked"><FontAwesomeIcon icon={faLockAlt} /></div>}
				{isUnlocked && isComplete && <div className="done"></div>}
				{isUnlocked && !isComplete && (<>
					<CircleProgress progress={progress} />
					<div className="count">{progression.completed}</div>
				</>)}
			</div>
			<div className="xp">{`+${data.publicData.Xp} xp`}</div>
		</div>
	);
}

export default function Missions() {
	const missions = useMissions(MISSIONS_TYPES.CHALLENGE);

	return (
		<>
			<div className="missions">
				{missions.map((mission => (
					<Mission data={mission} key={mission.id} />
				)))}
			</div>
			<div className="weekly-mission rounded">
				<div className="title">Challenge Week 14</div>
				<div className="text">Complete all challenge to win a 30 days friends pass and unlock your grand master avatar profile!</div>
				<div className="time-remaining">
					<FontAwesomeIcon icon={faTimer} /> 5D - 12H
				</div>
			</div>
		</>
	);
}