import React from 'react';
import './scoreboard.scss';
import Gage from './Gage';

const ScoreBoard = ({
	moves,
	handleRestart,
	accentColor,
}: {
	moves: number;
	handleRestart: any;
	bestScore: number;
	accentColor: string;
}) => {
	return (
		<div className="col-12 col-lg-8 row justify-content-end">
			<div className="scoreboard">
				<div className="row justify-content-end">
					<button className="restart" onClick={handleRestart}>
						RESTART
					</button>
				</div>
				{/* {localStorage.getItem('mini-games-matching-best-score') && (
					<div className="">
						<span className="bold">Best Score:</span> {bestScore}
					</div>
				)} */}
				<div className="row moves">
					<div className="col moves_label">
						<div className="row">
							<span className="label">Moves:</span>
							<span className="value">{moves}</span>
						</div>
					</div>
					<div className="col">
						<Gage moves={moves} accentColor={accentColor} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ScoreBoard;
