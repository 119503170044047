import React, { CSSProperties, useCallback, useLayoutEffect, useRef, useState } from 'react';
import useAdPlacement from '../../hooks/useAdPlacement';
import { CSSTransition } from 'react-transition-group';
import Button from './Button';
import { RewardTypes, XRAdActivityTypes } from '../../api/enums';
import usePlayer from '../../hooks/usePlayer';
import useAnimationFrame from '../../hooks/useAnimationFrame';
import useGlobalVariables from '../../hooks/useGlobalVariables';

type RewardedAdProps = {
	id: string;
	autoFetch?: boolean;
	onPopUpClosed?: () => void;
};

enum States {
	Playing = 'ad-playing',
	Ended = 'ad-ended',
	Intro = 'intro',
	Idle = 'idle',
}

export default function RewardedAd({
	id = '',
	autoFetch = false,
	onPopUpClosed,
}:RewardedAdProps) {
	const {
		video: videoUrl,
		isActive,
		prices,
		rewards,
		skipAd,
		removeAd,
		reportAdActivity,
		refresh,
	} = useAdPlacement(id, autoFetch);

	const { AdDialog } = useGlobalVariables();

	const {
		currencies,
	} = usePlayer();

	const price = (prices as Record<string, number>)?.CO || 0;

	const [isWatched, setIsWatched] = useState(false);
	const ref = useRef<HTMLDivElement>(null);
	const video = useRef<HTMLVideoElement>(null);

	function onSkipAd() {
		skipAd().then(() => {
			refresh();
		});
	}

	const [state, setState] = useState<States>(States.Idle);
	const [countdown, setCountdown] = useState<number>(0);

	function onWatchAd() {
		setState(States.Playing);
		video.current.play();
		reportAdActivity(XRAdActivityTypes.Start);
	}

	function onAdEnded() {
		reportAdActivity(XRAdActivityTypes.End);
		setState(States.Ended);
	}
	
	function onAdClosed() {
		reportAdActivity(XRAdActivityTypes.Closed);
		removeAd();
		setIsWatched(true);
	}

	const canSkip = currencies.CO >= price;

	const loop = useCallback(() => {
		if (video.current) {
			const time = Math.floor(video.current.duration) - Math.floor(video.current.currentTime) || 0;
	
			if (time !== countdown) {
				setCountdown(time);
			}
		}
	}, [countdown]);
	useAnimationFrame(loop);

	useLayoutEffect(() => {
		if (state === States.Idle && isActive && !isWatched) {
			setState(States.Intro);
			reportAdActivity(XRAdActivityTypes.Opened);
		}
	}, [state, isWatched, isActive]);

	let a = 0;
	let b = 0;

	const currencyReward = rewards?.find(reward => reward.dataType === RewardTypes.CurrencyGrant);
	const statReward = rewards?.find(reward => reward.dataType === RewardTypes.PlayerStatistic);

	return (
		<CSSTransition nodeRef={ref} in={!isWatched && isActive} timeout={{ appear: 1200, enter: 1200, exit: 900 }} appear mountOnEnter unmountOnExit onExited={onPopUpClosed}>
			<div ref={ref} className='rewarded-ad enter'>
				<div className="background"></div>
				<div className={`rewarded-ad-panel ${state}`}>
					<div className="watch-to-win">
						<div className="content">
							<img src={AdDialog.Logo} alt="" />
							<p style={{ '--i': ++a } as CSSProperties} className="message">{AdDialog.Message}</p>
							<div className="btns" style={{ '--i': ++a } as CSSProperties}>
								<div className="watch-btn-wrapper">
									<Button className="watch" label='Watch Ad' onClick={onWatchAd} />
									{(currencyReward && statReward) && (
										<p>Gain {currencyReward.dataVal} {currencyReward.dataKey}<br /> and {statReward.dataVal} {statReward.dataKey}</p>
									)}
								</div>
								<Button className={`skip ${canSkip ? '' : 'disabled'}`} label={`Skip (${price} CO)`} onClick={canSkip && onSkipAd} />
							</div>
						</div>
						<img src={AdDialog.Image} alt="" className="side-img" />
					</div>
					<div className="ad-space">
						<div className="countdown">{countdown}</div>
						<video src={videoUrl} ref={video} playsInline muted onEnded={onAdEnded} />
					</div>
					<div className="ad-ended">
						<h1 style={{ '--i': ++b } as CSSProperties}>Congrats!<br/>You've received:</h1>
						<div className="rewards">
							{rewards && rewards.map((reward, i) => (
								<div key={i} className="reward" style={{ '--i': ++b } as CSSProperties}>
									{reward.dataType === RewardTypes.CurrencyGrant && (<div className="currency"><div className="amt">{reward.dataVal} {reward.dataKey}</div></div>)}
									{reward.dataType === RewardTypes.PlayerStatistic && (<div className="stat"><div className="amt">{reward.dataVal} {reward.dataKey}</div></div>)}
								</div>
							))}
						</div>
						<div className="btns" style={{ '--i': ++b } as CSSProperties}>
							<Button className="close" label="close" onClick={onAdClosed} />
						</div>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
}