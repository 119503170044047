import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState, useAppDispatch } from '../redux/config/store';
import { getStoreLoadout } from '../redux/xr_store';
import useGlobalVariables from './useGlobalVariables';
import usePlayer from './usePlayer';
import useStats from './useStats';

const ALL_PRODUCT_CATEGORY_ID = -1;
const BRAND_CURATED_CATEGORY_ID = -2;

export default function useXRStore() {
	const dispatch = useAppDispatch();
	const { StorePage, isLoaded: StorePageLoaded } = useGlobalVariables();

	const { playerId } = usePlayer();
	
	const catalog = useSelector((state: IRootState) => state.catalog.items);
	const store = useSelector((state:IRootState) => state.xrStore.loadout);
	
	const [IsReady, SetIsReady] = useState(store.length !== 0);
	const [activeCategoryId, setActiveCategoryId] = useState<number>(ALL_PRODUCT_CATEGORY_ID);

	useEffect(() => {
		if (playerId) {
			dispatch(getStoreLoadout()).then(() => { SetIsReady(true); });
		}
	}, [store, playerId]);

	const ActiveCategory = StorePage?.StoreCategories?.find(c => c.SectionId === activeCategoryId);

	const { favorite_brand: favoriteBrand } = useStats();

	const Products = useMemo(() => {
		if (!StorePageLoaded || !IsReady || catalog.length === 0) {
			return [];
		}

		const activeTiles = StorePage.StoreCategories.reduce<IXRStoreTile[]>((acc, category) => {
			const tiles = store.find(section => section.id === category.SectionId)?.tiles ?? [];

			if (activeCategoryId === BRAND_CURATED_CATEGORY_ID) {
				const brandTiles = tiles.filter(tile => tile.customPayload.brandId === favoriteBrand);
				return [...acc, ...brandTiles];
			}

			if (activeCategoryId === ALL_PRODUCT_CATEGORY_ID || category.SectionId === activeCategoryId) {
				return [...acc, ...tiles];
			}

			return acc;
		}, []);

		return activeTiles.map(tile => ({
			tile,
			item: catalog.find(product => product.itemId === tile.content.ItemId),
		}));
	}, [activeCategoryId, StorePageLoaded, IsReady, catalog, favoriteBrand]);

	const isBrandCurated = activeCategoryId === BRAND_CURATED_CATEGORY_ID;

	return {
		StorePage,
		IsReady,
		ActiveCategory,
		setActiveCategoryId,
		Products,
		isBrandCurated,
	};
}