import React, { memo, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import useGlobalVariables from '../../hooks/useGlobalVariables';
import { raw } from '../../utils/textUtils';
import Button from '../ui/Button';
import Loading from '../ui/Loading';
import useBackground from '../../hooks/useBackground';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from '../../Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/sharp-solid-svg-icons';
import useShows from '../../hooks/useShows';

function Show() {

	const { isLoaded } = useGlobalVariables();
	const page = useRef<HTMLDivElement>(null);

	const { getShowById } = useShows();
	const { id } = useParams();

	const show = getShowById(id);

	const { setBackground } = useBackground();
	useEffect(() => {
		setBackground(show?.fullSizeImg);
	});
	
	if (!isLoaded) return (
		<Loading isLoading={isLoaded} />
	);

	return (
		<CSSTransition nodeRef={page} key="show" timeout={1200} in={true} appear>
			<div className="page show" ref={page}>
				<div className="page-content">
					<div className="show-details">
						<Link to={ROUTES.ROOT + ROUTES.SHOWS} className="back-btn">
							<FontAwesomeIcon icon={faArrowLeft} /> Back to shows
						</Link>
						<div className="show-title" {...raw(show.title)} />
						<div className="tagline" {...raw(show.tagline)} />
						<Button className="watch-btn" to={ROUTES.ROOT + ROUTES.MATCH} label={'Watch now'} hasArrow />
					</div>
				</div>
			</div>
		</CSSTransition>
	);
}

export default memo(Show);
