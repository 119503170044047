import React from 'react';
import usePlayer from '../../hooks/usePlayer';
import useXP from '../../hooks/useXP';
import LoadingSpinner from '../icons/LoadingSpinner';
import CircleProgress from './CircleProgress';

export default function Avatar() {
	const { AvatarUrl } = usePlayer();

	const { progress } = useXP();

	return (
		<div className="avatar">
			<CircleProgress progress={progress} />
			<div className="image-ctn">
				{AvatarUrl && <img src={AvatarUrl} alt="" />}
				{!AvatarUrl && <LoadingSpinner className="spinner" />}
			</div>
		</div>
	);
}