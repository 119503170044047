import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCheck, faStopwatch, faThumbsDown, faTimes } from '@fortawesome/sharp-solid-svg-icons';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import gsap from 'gsap';
import { useActivePrediction, usePredictionRewards, useUnclaimedPredictions } from '../../hooks/useChallenges';
import useGlobalVariables from '../../hooks/useGlobalVariables';
import usePlayerData from '../../hooks/usePlayerData';
import { useAppDispatch } from '../../redux/config/store';
import { updatePlayerData } from '../../redux/playerData';
import { writePlayerEvent } from '../../redux/playfab';
import { answerPredictions, claimPredictionRewards, fetchUnclaimedPredictions } from '../../redux/predictions';
import LoadingSpinner from '../icons/LoadingSpinner';
import { PREDICTION_MATCH_ID } from '../../Constants';

export enum PredictionStates {
	WAITING = 'WAITING',
	ANSWERING = 'ANSWERING',
	ANSWERED = 'ANSWERED',
	TIMED_OUT = 'TIMED_OUT',
	RESOLVED = 'RESOLVED',
	CLAIMED = 'CLAIMED',
}

const Prediction = forwardRef(({
	closePredictions,
}:{
	closePredictions?: React.MouseEventHandler;
}, forwardedRef: React.ForwardedRef<HTMLDivElement>) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(true);
	const [countdown, setCountdown] = useState<number>(0);

	const pollWrapper = useRef<HTMLDivElement>(null);

	const dispatch = useAppDispatch();

	const [predictionAnswers, setPredictionAnswers] = useState<Record<string, string>>({});
	const activePrediction = useActivePrediction();
	const { playerData } = usePlayerData();
	const [predictionState, setPredictionState] = useState<PredictionStates>(PredictionStates.WAITING);
	const { isLoaded: isGlobalVariablesLoaded } = useGlobalVariables();
	const resolvePredictionTimeout = useRef<NodeJS.Timeout>();

	useEffect(() => {
		setPredictionState(PredictionStates.WAITING);
		dispatch(writePlayerEvent({ name: 'match_started' }));

		return () => {
			clearTimeout(resolvePredictionTimeout.current);
		};
	}, []);

	useEffect(() => {
		if (isGlobalVariablesLoaded) {
			dispatch(writePlayerEvent({
				name: 'trigger_match_prediction',
				body: {
					match_id: PREDICTION_MATCH_ID,
				},
			}));
		}
	}, [isGlobalVariablesLoaded]);

	const answerDelay = activePrediction ? activePrediction.voteExpiration - (activePrediction.timestamp / 1000) : 0; // voteExpiration is in seconds, timestamp is in ms

	useEffect(() => {
		if (activePrediction && !activePrediction.isAnswered && predictionState === PredictionStates.WAITING) {
			setPredictionState(PredictionStates.ANSWERING);
		}
	}, [activePrediction, predictionState]);

	useEffect(() => {
		if (predictionState === PredictionStates.ANSWERING) {
			const tweenValue = { value: answerDelay };
			const tween = gsap.to(tweenValue, {
				value: 0,
				ease: 'none',
				duration: answerDelay,
				onStart: () => setCountdown(answerDelay),
				onUpdateParams: [tweenValue],
				onUpdate: (s) => {
					setCountdown(s.value);
				},
				onComplete: () => {
					setPredictionState(PredictionStates.TIMED_OUT);
				},
			});

			return () => {
				tween.kill();
			};
		}
		return () => {};
	}, [predictionState]);

	const submitAnswer = useCallback(async (choice: string) => {
		setPredictionState(PredictionStates.ANSWERED);
		
		const answers = {
			[activePrediction.data.questions[0].id]: choice,
		};
		setPredictionAnswers(answers);

		await dispatch(answerPredictions({
			InstanceId: activePrediction.instanceId, 
			Answers: answers,
		}));
		await dispatch(updatePlayerData({
			Challenges: {
				...playerData.Challenges,
				[activePrediction.instanceId]: answers,
			},
		}));

		// For demo purposes only, resolve prediction automatically after 5 seconds
		resolvePredictionTimeout.current = setTimeout(async () => {
			await dispatch(writePlayerEvent({
				name: 'prediction_points_resolve',
				body: {
					matchId: PREDICTION_MATCH_ID,
					winningTeam: 'PORT',
				},
			}));
			await dispatch(fetchUnclaimedPredictions());
			setPredictionState(PredictionStates.RESOLVED);
			setIsExpanded(true);
		}, 5000);
	}, [activePrediction, dispatch]);

	const unclaimedPredictions = useUnclaimedPredictions();

	const claimReward = async () => {
		if (!unclaimedPredictions || predictionState === PredictionStates.WAITING) return;
		setPredictionState(PredictionStates.WAITING);
		try {
			const claimPredictionsPromises = unclaimedPredictions.map(prediction => dispatch(claimPredictionRewards({ InstanceId: prediction.instanceId })));
			await Promise.all(claimPredictionsPromises);
		} catch (e) {
			console.error('Failed to claim rewards for predictions:', unclaimedPredictions.map(p => p.instanceId));
		}
		setPredictionState(PredictionStates.CLAIMED);
	};

	const countdownProgress = countdown / answerDelay;

	const rewards = usePredictionRewards(unclaimedPredictions[0]);

	const gotCorrectAnswer = () => {
		return activePrediction?.data?.questions?.some(question => {
			return predictionAnswers?.[question.id] === activePrediction?.result?.[question.id];
		});
	};

	let content = null;
	switch (predictionState) {
		case PredictionStates.ANSWERING:
			content = (
				<div className="poll-transition" key="prediction">
					{activePrediction?.data?.questions && activePrediction?.data?.questions.map(question => (
						<div key={question.id}>
							<div className="poll-head">
								<div className="question">
									{question.question}
								</div>
								<div className="countdown">
									<svg viewBox="-20 -20 40 40" className="circle">
										<circle r="19" stroke="red" strokeWidth={3} transform="rotate(-90) scale(1, -1)" strokeDasharray={38 * Math.PI} strokeDashoffset={(1 - countdownProgress) * 38 * Math.PI} fill="transparent" />
									</svg>
									<div className="seconds">{Math.ceil(countdown)}</div>
								</div>
							</div>
							<div className="choices-wrapper">
								{question.choices.map(choice => (
									<button className="choice" key={choice.id} onClick={() => submitAnswer(choice.code)}>
										{choice.label}
									</button>
								))}
							</div>
						</div>
					))}
				</div>
			);
			break;
		case PredictionStates.WAITING:
			content = (
				<div className="poll-transition poll-content-box is-loading" key="loading">
					<LoadingSpinner className="spinner" />
				</div>
			);
			break;
		case PredictionStates.TIMED_OUT:
			content = (
				<div className="poll-transition poll-content-box" key="timed-out">
					<button onClick={closePredictions} className="close-btn"><FontAwesomeIcon icon={faTimes} /></button>
					<FontAwesomeIcon icon={faStopwatch} />
					<p>Oups! You did not submit your prediction in time.</p>
				</div>
			);
			break;
		case PredictionStates.ANSWERED:
			content = (
				<div className="poll-transition poll-content-box" key="timed-out">
					<FontAwesomeIcon icon={faCheck} />
					<p>Answer submitted! Stay tuned.</p>
				</div>
			);
			break;
		case PredictionStates.RESOLVED:
			content = (
				<div className="poll-transition poll-content-box" key="resolved">
					{gotCorrectAnswer() ? (
						<>
							<FontAwesomeIcon icon={faCheck} />
							<div>
								<p>Your prediction was correct!</p>
								{rewards && rewards.map(reward => (
									<p className="reward" key={reward.id}>+ {reward.dataVal} {reward.dataKey}</p>
								))}
								<button className="button" onClick={claimReward}>Claim reward</button>
							</div>
						</>
					) : (
						<>
							<button onClick={closePredictions} className="close-btn"><FontAwesomeIcon icon={faTimes} /></button>
							<FontAwesomeIcon icon={faThumbsDown} />
							<p>Oups! Your prediction was incorrect. Better luck next time.</p>
						</>
					)}
				</div>
			);
			break;
		case PredictionStates.CLAIMED:
			content = (
				<div className="poll-transition poll-content-box" key="claimed">
					<button onClick={closePredictions} className="close-btn"><FontAwesomeIcon icon={faTimes} /></button>
					<p>Thank you for participating!</p>
				</div>
			);
			break;
		default:
			content = (
				<div className="poll-transition poll-content-box is-loading" key="loading">
					<LoadingSpinner className="spinner" />
				</div>
			);
			break;
	}

	return (
		<div className="prediction" ref={forwardedRef}>
			<CSSTransition in={isExpanded} timeout={700} >
				<div className="poll-wrapper" ref={pollWrapper}>
					<SwitchTransition>
						<CSSTransition timeout={800} mountOnEnter unmountOnExit key={predictionState}>
							{content}
						</CSSTransition>
					</SwitchTransition>
				</div>
			</CSSTransition>
			<button className={`expand-button ${isExpanded ? 'expanded' : ''}`} onClick={() => setIsExpanded(prev => !prev)}>
				<FontAwesomeIcon icon={faAngleDown} />
			</button>
		</div>
	);
});

export default Prediction;
