import React, { CSSProperties, memo, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { ROUTES } from '../../Constants';
import useLogin from '../../hooks/useLogin';
import usePlayer from '../../hooks/usePlayer';
import { raw } from '../../utils/textUtils';
import useBackground from '../../hooks/useBackground';

function Login() {
	const {
		BackgroundImage,
		SidebarTitle: Title,
		ExistingUserAvatar,
		NewUser,
		QrMessage,
		ExistingUserGreet,

		OnClickExistingUser,
		OnClickNewUser,
	} = useLogin();
	const page = useRef<HTMLDivElement>(null);

	const { setBackground } = useBackground();
	useEffect(() => {
		setBackground(BackgroundImage);
	});

	const { playerId } = usePlayer();

	return (
		<CSSTransition nodeRef={page} key="login" timeout={{ appear: 5000, enter: 5000, exit: 1200 }} in={true} appear>
			<div className="page login" ref={page}>
				{playerId && <Navigate to={ROUTES.ROOT} replace={true} />}
				<div className="page-content">
					<div className="modal">
						<div className="title animate" {...raw(Title)} style={{ '--i': 0 } as CSSProperties} />
						<div className="splits">
							<div className="left">
								<div className="user-btn existing animate" onClick={OnClickExistingUser} style={{ '--i': 1 } as CSSProperties}>
									<div className="image">
										<img src={ExistingUserAvatar} alt="" />
									</div>
									<div className="text" {...raw(ExistingUserGreet)} />
								</div>
								<div className="user-btn new animate" onClick={OnClickNewUser} style={{ '--i': 2 } as CSSProperties}>
									<div className="image">
										<svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill="none" viewBox="0 0 200 200"><circle cx="100" cy="100" r="100" fill="#29292B"/><path fill="#626262" d="M97.143 77h5.714v50h-5.714V77Z"/><path fill="#626262" d="M125 99.143v5.714H75v-5.714h50Z"/></svg>
									</div>
									<div className="text" {...raw(NewUser)} />
								</div>
							</div>
							<div className="right">
								<div className="qr-ctn animate" style={{ '--i': 3 } as CSSProperties}>
									<div className="qr">
										<img width="270" height="270" src="/telco-demo-qr-code.svg" alt="" />
									</div>
									<div className="text" {...raw(QrMessage)} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
}

export default memo(Login);