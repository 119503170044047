import { useEffect } from 'react';
import useEventCallback from './useEventCallback';

export default function useClickOutsideOf({
	enabled = true,
	targetSelector,
	onInteractOutside,
}:{
	enabled?: boolean,
	targetSelector: string,
	onInteractOutside?: (e: MouseEvent) => void,
}) {
	const callback = useEventCallback(onInteractOutside);

	useEffect(() => {
		if (!enabled) return () => {};

		const dismissIfInteractOutside = (e: MouseEvent) => {
			if (!(e.target as HTMLElement).closest(targetSelector)) {
				callback?.(e);
			}
		};

		// If this hook is enabled by a click event (for ex. opening a toggle),
		// the callback will be called immediately. So add a timeout to wait
		// for the event to be finished before adding the listener
		setTimeout(() => {
			document.addEventListener('click', dismissIfInteractOutside);
		}, 1);

		return () => {
			document.removeEventListener('click', dismissIfInteractOutside);
		};
	}, [enabled, targetSelector]);
}
