import React, { useEffect, useRef, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { ROUTES } from '../Constants';
import useLogin from '../hooks/useLogin';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { LoginMethods } from '../redux/env';
import { clearRFIDTag } from '../redux/rfid';

export default function RFIDLogin() {
	const tag = useAppSelector(state => state.rfid.latestTag);
	const dispatch = useAppDispatch();

	const [waitForChangeRoute, setWaitForChangeRoute] = useState(false);

	const isLoginPage = Boolean(useMatch(ROUTES.ROOT + ROUTES.LOGIN));

	const {
		OnRFID,
		IsLoggingIn,
		loggingMethod,
	} = useLogin();

	useEffect(() => {
		if (tag) {
			if (isLoginPage) {
				setWaitForChangeRoute(true);
				OnRFID(tag);
				dispatch(clearRFIDTag());
			}
		}
	}, [tag, isLoginPage, setWaitForChangeRoute]);

	useEffect(() => {
		let to;
		if (waitForChangeRoute && !IsLoggingIn) {
			to = setTimeout(() => {
				setWaitForChangeRoute(false);
			}, 500);
		}

		return () => {
			clearTimeout(to);
		};
	}, [IsLoggingIn, waitForChangeRoute]);

	const loginInAppFader = useRef<HTMLDivElement>(null);

	return (
		<div className="rfid-login">
			<CSSTransition nodeRef={loginInAppFader} in={!isLoginPage && IsLoggingIn && loggingMethod === LoginMethods.RFID} appear timeout={600} mountOnEnter unmountOnExit>
				<div className="login-in-app-fader" ref={loginInAppFader}></div>
			</CSSTransition>
		</div>
	);
}