import * as React from 'react';

const Checkbox = () => (
	<svg
		width={55}
		height={48}
		viewBox="0 0 55 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_818_2167)">
			<path
				d="M47.943 20.9165C46.7866 20.9165 45.8435 21.8609 45.8435 23.0187V43.2457H4.69907V4.65471H36.0499C37.2063 4.65471 38.1495 3.71031 38.1495 2.55245C38.1495 1.39459 37.2063 0.450195 36.0499 0.450195H0.5V47.4502H50.0425V23.0187C50.0425 21.8609 49.1058 20.9165 47.943 20.9165Z"
				fill="var(--color, #ffffff)"
			/>
			<path
				d="M50.9211 5.15909L26.0174 30.0951L13.3814 17.4363C12.561 16.6148 11.2302 16.6148 10.4098 17.4363C9.58933 18.2578 9.58933 19.5903 10.4098 20.4118L26.0174 36.0396L53.8863 8.13459C54.7067 7.3131 54.7067 5.98059 53.8863 5.15909C53.0658 4.33759 51.7415 4.33759 50.9211 5.15909Z"
				fill="var(--color, #ffffff)"
			/>
		</g>
		<defs>
			<clipPath id="clip0_818_2167">
				<rect
					width={54}
					height={47}
					fill="white"
					transform="translate(0.5 0.450195)"
				/>
			</clipPath>
		</defs>
	</svg>
);
export default Checkbox;
