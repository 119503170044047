import React, { useEffect, useRef, useState } from 'react';
import useXP from '../../hooks/useXP';
import { guid } from '../../utils/guid';

interface IXPFloater {
	amount: number;
	id: string;
	onDelete?: (floater:IXPFloater) => void,
}

function XPFloater(props: IXPFloater) {
	useEffect(() => {
		const to = setTimeout(() => {
			props.onDelete?.(props);
		}, 200000);

		return () => clearTimeout(to);
	}, []);

	return (
		<div className="xp-floater">+{props.amount}XP</div>
	);
}

export default function XpFloaters() {
	const lastXp = useRef<number | null>(null);
	const { xp } = useXP();

	const [floaters, setFloaters] = useState<IXPFloater[]>([]);

	function onDelete(floater: IXPFloater) {
		setFloaters(floaters.filter(f => f.id !== floater.id));
	}

	useEffect(() => {
		if (lastXp.current === null) lastXp.current = xp;

		if (xp !== lastXp.current) {
			const diff = xp - lastXp.current;

			if (diff > 0) {
				setFloaters(floaters.concat({ amount: diff, id: guid() }));
			}

			lastXp.current = xp;
		}
	}, [xp, lastXp]);

	return (
		<div className="xp-floaters">
			{floaters.map(f => <XPFloater key={f.id} {...f} onDelete={onDelete} />)}
		</div>
	);
}