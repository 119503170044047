import * as React from 'react';

const TripleProgressBars = () => (
	<svg
		width={40}
		height={40}
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g fill="var(--color, #f9f9f9)">
			<path d="M0 1V11H40V1H0ZM30 9H2V3H30V9Z" />
			<path d="M0 25H40V15H0V25ZM2 17H14V23H2V17Z" />
			<path d="M0 39H40V29H0V39ZM2 31H24V37H2V31Z" />
		</g>
	</svg>
);
export default TripleProgressBars;
