import React, { memo, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import useGlobalVariables from '../../hooks/useGlobalVariables';
import { raw } from '../../utils/textUtils';
import Loading from '../ui/Loading';
import { Options } from '@splidejs/splide';
import ShowCard from '../ui/ShowCard';
import useShows from '../../hooks/useShows';
import usePlayer from '../../hooks/usePlayer';
import useCityBackground from '../../hooks/useCityBackground';

const ACTIVE_SLIDE_INDEX = 1;

function Media() {
	const { MediaPage, isLoaded } = useGlobalVariables();
	const page = useRef<HTMLDivElement>(null);

	const { showList, favorites } = useShows();
	const { DisplayName } = usePlayer();

	const {
		PageTitle,
		FavoritesTitle,
	} = MediaPage || {};

	useCityBackground();

	const sliderWrapper = useRef<HTMLDivElement>(null);

	if (!isLoaded) return (
		<Loading isLoading={isLoaded} />
	);

	const sliderOptions: Options = {
		arrows: false,
		autoWidth: true,
		perMove: 1,
		gap: '16px',
		pagination: false,
		drag: true,
		snap: true,
		type: 'loop',
		waitForTransition: true,
		updateOnMove: false,
		focus: ACTIVE_SLIDE_INDEX,
	};

	const onSliderReady = ({ root }) => {
		const positionActiveFrame = () => {
			const activeSlide = root.querySelector('.splide__slide.is-active.is-visible') as HTMLElement;
			const track = root.querySelector('.splide__track');

			const { left: slideLeft } = activeSlide.getBoundingClientRect();
			const { left: trackLeft } = track.getBoundingClientRect();
			root.style.setProperty('--active-slide-left', `${slideLeft - trackLeft}px`);
		};

		const addIndicesToVisibleCards = () => {
			const cards = Array.from(root.querySelectorAll('.splide__slide')) as HTMLElement[];
			cards
				.filter(card => {
					const { left, right } = card.getBoundingClientRect();
					return left <= window.innerWidth
						&& right >= 0;
				})
				.forEach((card, i) => {
					card.style.setProperty('--card-index', i.toString());
				});
		};

		positionActiveFrame();
		addIndicesToVisibleCards();
	};

	return (
		<CSSTransition nodeRef={page} key="media" timeout={1200} in={true} appear>
			<div className="page media" ref={page}>
				<div className="page-content">
					<div className="page-title">
						<div className="upper-title" {...raw(`Good evening ${DisplayName}!`)} />
						<div className="title" {...raw(PageTitle)} />
					</div>
					<div className="main-shows-slider-wrapper" ref={sliderWrapper}>
						<Splide options={sliderOptions} onReady={onSliderReady}>
							{showList.map((show) => (
								<SplideSlide key={show.showId}>
									<ShowCard show={show} />
								</SplideSlide>
							))}
						</Splide>
					</div>
					<div className="favorites">
						<div className="favorites-title">{FavoritesTitle}</div>
						<div className="shows-grid">
							{favorites.map((show) => (
								<ShowCard key={show.showId} show={show} small />
							))}
						</div>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
}

export default memo(Media);