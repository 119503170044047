/* eslint-disable @typescript-eslint/naming-convention */
import { useSelector } from 'react-redux';
import { IRootState } from '../redux/config/store';
import { useMemo } from 'react';
import { ITEM_CLASSES } from '../Constants';
import useStats from './useStats';

export default function useInventory() {
	const inventory = useSelector((state:IRootState) => state.inventory);
	const { favorite_brand } = useStats();

	const userProducts = useMemo(() => {
		return inventory.filter(item => {
			return item.playfab.ItemClass === ITEM_CLASSES.SHOP_PRODUCT
				&& item.publicData.BrandId === favorite_brand;
		});
	}, [inventory, favorite_brand]);

	return {
		userProducts,
	};
}
