import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IRootState, useAppDispatch } from '../redux/config/store';
import { getLoginPageContent } from '../redux/env';

export default function useEnv() {
	const dispatch = useAppDispatch();
	const LoginPageContent = useSelector((state:IRootState) => state.env.LoginPageContent);
	const Lang = useSelector((state:IRootState) => state.env.Lang);

	useEffect(() => {
		if (!LoginPageContent) {
			dispatch(getLoginPageContent());
		}
	}, [LoginPageContent]);

	return {
		LoginPageContent,
		Lang,
	};
}