import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { updateDisplayName } from '../redux/playfab';

export default function usePlayer() {
	const {
		PlayFabId,
		DisplayName,
		AvatarUrl,
		currencies,
	} = useAppSelector((state) => state.playfab);
	
	const dispatch = useAppDispatch();

	return {
		playerId: PlayFabId,
		currencies,
		DisplayName,
		AvatarUrl,
		updateDisplayName: (displayName:string) => dispatch(updateDisplayName(displayName)),
	};
}