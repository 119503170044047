/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';
import useBackground from './useBackground';
import useGlobalVariables from './useGlobalVariables';
import useStats from './useStats';

export default function useCityBackground() {
	const { CitiesBackgrounds } = useGlobalVariables();
	const { biggest_city_nearby } = useStats();
	const { setBackground } = useBackground();

	useEffect(() => {
		setBackground(CitiesBackgrounds?.[`city_${biggest_city_nearby}`] ?? CitiesBackgrounds?.default);
	}, [CitiesBackgrounds, biggest_city_nearby]);
}
