import React from 'react';

export default function Badge({ item }:{ item: Badge }) {
	return (
		<div className={`badge rounded ${item.isInInventory ? 'unlocked' : ''}`} key={item.itemId}>
			{item.data.Icon && <img src={item.data.Icon} alt={item.playfab.DisplayName} />}
			{!item.data.Icon && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67 67"><g transform="translate(-.493 -.106)"><circle cx="33.5" cy="33.5" r="33.5" transform="translate(.493 .106)" fill="#1a1a1a"/><g transform="translate(23.768 18.816)" fill="none" stroke="#ffc424" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"><circle cx="9.5" cy="9.5" r="9.5" transform="translate(-.275 .29)"/><path d="M4.572 18.064 2.925 30.47l6.807-4.084 6.806 4.084-1.647-12.42"/></g></g></svg>}
			<div className="name">{item.playfab.DisplayName}</div>
			<div className="rarity">{item.data.Rarity}</div>
		</div>
	);
}