import * as React from 'react';

const Star = () => (
	<svg
		width={40}
		height={40}
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M20 30.12L8 39L12.3 24.52L0 15H15.24L20 1L24.76 15H40L27.7 24.52L32 39L20 30.12Z"
			fill="var(--color, #f9f9f9)"
		/>
	</svg>
);
export default Star;
