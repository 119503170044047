import { useSelector } from 'react-redux';
import { IRootState, useAppDispatch } from '../redux/config/store';
import { getPlayerData, updatePlayerData } from '../redux/playerData';

export default function usePlayerData() {
	const playerData = useSelector((state:IRootState) => state.playerData.data);
	const isPlayerDataLoaded = useSelector((state:IRootState) => state.playerData.loaded);

	const dispatch = useAppDispatch();

	return {
		playerData,
		isPlayerDataLoaded,
		updatePlayerData: (data:Record<string, unknown>, permission:'Public' | 'Private' = 'Private') => dispatch(updatePlayerData({ ...data, permission })),
		refresh: () => dispatch(getPlayerData()),
	};
}