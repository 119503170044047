import React, { memo, useLayoutEffect, useRef, CSSProperties, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import videojs from 'video.js';
import 'videojs-overlay';
import '@videojs/http-streaming';
import { useNavigate } from 'react-router-dom';

import { /*PREDICTION_MATCH_ID,/** */ ROUTES } from '../../Constants';
import useGlobalVariables from '../../hooks/useGlobalVariables';
import useMatchAnalytics from '../../hooks/useMatchAnalytics';
import ChatBox from '../chat/ChatBox';
import useBackground from '../../hooks/useBackground';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/sharp-solid-svg-icons';
import Checkbox from '../icons/Checkbox';
import VideoPlay from '../icons/VideoPlay';
import { useInactivity } from '../../hooks/useInactivity';
import RewardedAd from '../ui/RewardedAd';
import Prediction from '../ui/Prediction';
// import { writePlayerEvent } from '../../redux/playfab';
// import { useAppDispatch } from '../../redux/config/store';

function Match() {
	const navigate = useNavigate();
	// const dispatch = useAppDispatch();
	const videoContainer = useRef<HTMLVideoElement>(null);
	const page = useRef<HTMLDivElement>(null);

	const {
		MatchPage,
		AppSettings,
	} = useGlobalVariables();

	const {
		Hls,
		BackgroundImage,
	} = MatchPage || {};

	const style = {
		'--background-image': `url(${BackgroundImage})`,
	} as CSSProperties;

	useMatchAnalytics();

	const { setBackground } = useBackground();
	useEffect(() => {
		setBackground('');
	});

	useLayoutEffect(() => {
		if (!Hls || !videoContainer.current) return () => {};
		
		const player = videojs(videoContainer.current);

		player.src({
			src: Hls,
		});

		return () => {
			player.dispose();
		};
	}, [Hls]);

	function onClose() {
		navigate(ROUTES.ROOT + ROUTES.SHOWS);
	}

	const chatWrapper = useRef<HTMLDivElement>(null);

	const [isMenuVisible, setIsMenuVisible] = useState<boolean>(true);
	const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
	const [showAd, setShowAd] = useState<boolean>(false);
	const [showPredictions, setShowPredictions] = useState<boolean>(false);

	const forceMenuVisible = isChatOpen || showPredictions;

	useInactivity({
		timeout: 5000,
		enabled: !forceMenuVisible,
		repeat: false,
		onTimeout: () => setIsMenuVisible(false),
		onActivity: () => setIsMenuVisible(true),
	});

	const predictions = useRef<HTMLDivElement>(null);

	return Hls && (
		<CSSTransition nodeRef={page} key="match" timeout={1200} in={true} appear>
			<div className="page match" style={style} ref={page}>
				<div className="bg"></div>
				<div onClick={onClose} className="close-match">
					<span className="text">Close <strong>Show</strong></span><span className="times">&times;</span>
				</div>
				<div className="video-wrapper">
					<video
						data-setup='{ "inactivityTimeout": 0 }'
						autoPlay
						muted
						controls
						playsInline
						className="video-js vjs-theme-city"
						loop
						ref={videoContainer}
					/>
					<div className={`video-player-menu ${(isMenuVisible || forceMenuVisible) ? 'visible' : ''}`}>
						<button onClick={() => setIsChatOpen(prev => !prev)}>
							<FontAwesomeIcon icon={faComment} />
						</button>
						<button onClick={() => setShowPredictions(true)}>
							<Checkbox />
						</button>
						<button onClick={() => setShowAd(true)}>
							<VideoPlay />
						</button>
					</div>
					<CSSTransition in={showPredictions} timeout={500} nodeRef={predictions} mountOnEnter unmountOnExit>
						<Prediction closePredictions={() => setShowPredictions(false)} ref={predictions} />
					</CSSTransition>
				</div>
				<CSSTransition in={isChatOpen} timeout={1000} nodeRef={chatWrapper} mountOnEnter unmountOnExit>
					<div className="chat-wrapper" ref={chatWrapper}>
						<ChatBox roomId={`match-${AppSettings.Sport}`} closeChatbox={() => setIsChatOpen(false)} />
					</div>
				</CSSTransition>
				{showAd && <RewardedAd id="video-player-rewarded-ad" autoFetch onPopUpClosed={() => setShowAd(false)} />}
			</div>
		</CSSTransition>
	);
}

export default memo(Match);