import React from 'react';
import './gage.scss';
import Rebates from './Rebates';

function hexToRGB(hex, alpha) {
	const r = parseInt(hex.slice(1, 3), 16),
		g = parseInt(hex.slice(3, 5), 16),
		b = parseInt(hex.slice(5, 7), 16);

	if (alpha) {
		return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
	} else {
		return 'rgb(' + r + ', ' + g + ', ' + b + ')';
	}
}
const Gage = ({
	moves,
	accentColor,
}: {
	moves: number;
	accentColor: string;
}) => {
	const rows = [];
	//when the accentColor is finnally loaded... load the bars
	if (accentColor) {
		for (let i = 0; i < 21; i++) {
			rows.push(
				<div
					className={'bar'}
					style={{
						backgroundColor: hexToRGB(accentColor, moves > i ? ' 0.5' : '1'),
					}}
					key={i}
				/>,
			);
		}
	}
	return (
		<div className="gage">
			<Rebates moves={moves} />
			<div className="row bars">{rows}</div>
			<div className="row breakpont-labels">
				<div className="col">1</div>
				<div className="col">8</div>
				<div className="col">15+</div>
			</div>
		</div>
	);
};

export default Gage;
