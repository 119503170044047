import React, { useEffect, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Button,
	DialogTitle,
} from '@mui/material';
import './memory.scss';
import useMemoryGameTheme from '../../../hooks/useMemoryGameTheme';
import { useAppDispatch } from '../../../redux/config/store';
import { writePlayerEvent } from '../../../redux/playfab';
import ScoreBoard from './memory/components/ScoreBoard';
import Card from './memory/components/Card';
import useCityBackground from '../../../hooks/useCityBackground';

function shuffleCards(array) {
	const length = array.length;
	for (let i = length; i > 0; i--) {
		const randomIndex = Math.floor(Math.random() * i);
		const currentIndex = i - 1;
		const temp = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temp;
	}
	return array;
}

export default function Memory() {
	const page = useRef<HTMLDivElement>(null);
	const theme = useMemoryGameTheme();
	const dispatch = useAppDispatch();
	const uniqueCardsArray: Array<object> = [
		{
			type: 'one',
			image: theme?.data.image_1,
		},
		{
			type: 'two',
			image: theme?.data.image_2,
		},
		{
			type: 'three',
			image: theme?.data.image_3,
		},
		{
			type: 'four',
			image: theme?.data.image_4,
		},
		{
			type: 'five',
			image: theme?.data.image_5,
		},
		{
			type: 'six',
			image: theme?.data.image_6,
		},
	];
	const [cards, setCards] = useState(() =>
		shuffleCards(uniqueCardsArray.concat(uniqueCardsArray)),
	);
	const [openCards, setOpenCards] = useState([]);
	const [clearedCards, setClearedCards] = useState({});
	const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
	const [moves, setMoves] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const [bestScore, setBestScore] = useState(
		JSON.parse(localStorage.getItem('mini-games-matching-best-score')) ||
			Number.POSITIVE_INFINITY,
	);
	const timeout = useRef(null);

	const disable = () => {
		setShouldDisableAllCards(true);
	};
	const enable = () => {
		setShouldDisableAllCards(false);
	};

	const checkCompletion = () => {
		if (
			Object.keys(clearedCards).length === uniqueCardsArray.length &&
			Object.keys(clearedCards).length > 0
		) {
			setShowModal(true);
			const highScore: any = Math.min(moves, bestScore);
			setBestScore(highScore);
			localStorage.setItem('mini-games-matching-best-score', highScore);
			if (moves <= cards.length) {
				if (moves === cards.length / 2) {
					dispatch(
						writePlayerEvent({
							name: 'memorygame_perfect_game',
							body: {
								time: Date.now(),
								numberMoves: moves,
								cards: cards.length / 2,
							},
						}),
					);
				} else {
					dispatch(
						writePlayerEvent({
							name: 'memorygame_won',
							body: {
								action: 'Game Won',
								time: Date.now(),
								numberMoves: moves,
								cards: cards.length / 2,
							},
						}),
					);
				}
			} else {
				dispatch(
					writePlayerEvent({
						name: 'memorygame_lost',
						body: {
							action: 'Game Lost',
							time: Date.now(),
							numberMoves: moves,
							cards: cards.length / 2,
						},
					}),
				);
			}
		}
	};

	const evaluate = () => {
		const [first, second] = openCards;
		enable();
		if (cards[first].type === cards[second].type) {
			setClearedCards((prev) => ({ ...prev, [cards[first].type]: true }));
			setOpenCards([]);
			return;
		}
		// This is to flip the cards back after 500ms duration
		timeout.current = setTimeout(() => {
			setOpenCards([]);
		}, 500);
	};
	const handleCardClick = (index: number) => {
		if (moves === 0) {
			dispatch(
				writePlayerEvent({
					name: 'memorygame_started',
					body: {
						time: Date.now(),
					},
				}),
			);
		}
		if (openCards.length === 1) {
			setOpenCards((prev) => [...prev, index]);
			setMoves((currentMoves) => currentMoves + 1);
			disable();
		} else {
			clearTimeout(timeout.current);
			setOpenCards([index]);
		}
	};

	useEffect(() => {
		let evaluateTimeout = null;
		if (openCards.length === 2) {
			evaluateTimeout = setTimeout(evaluate, 300);
		}
		return () => {
			clearTimeout(evaluateTimeout);
		};
	}, [openCards]);

	useCityBackground();

	useEffect(() => {
		checkCompletion();
	}, [clearedCards]);

	const checkIsFlipped = (index) => {
		return openCards.includes(index);
	};

	const checkIsInactive = (card) => {
		return Boolean(clearedCards[card.type]);
	};

	const handleRestart = () => {
		setClearedCards({});
		setOpenCards([]);
		setShowModal(false);
		setMoves(0);
		setShouldDisableAllCards(false);
		// set a shuffled deck of cards
		setCards(() => shuffleCards(uniqueCardsArray.concat(uniqueCardsArray)));
	};
	useEffect(() => {
		//when the images are finnally loaded... shuffle the cards
		return handleRestart();
	}, [theme?.data.image_1]);

	return (
		<CSSTransition nodeRef={page} key="memory" timeout={1200} in={true} appear>
			<div className="page memory" ref={page}>
				<div className="page-content">
					<div className="row mt-3">
						<div className="col-sm-12 col-md-4">
							<div className="sub-title">
								MATCHING <br />
								GAME
							</div>
						</div>
						<ScoreBoard
							bestScore={bestScore}
							moves={moves}
							handleRestart={handleRestart}
							accentColor={theme?.data?.accent_color as string}
						/>
					</div>
					<div className="col">
						<div className="row m-0 mb-3">
							{cards.length > 0 &&
								cards.map((card, index) => {
									return (
										<Card
											key={index}
											card={card}
											index={index}
											isDisabled={shouldDisableAllCards}
											isInactive={checkIsInactive(card)}
											isFlipped={checkIsFlipped(index)}
											onClick={handleCardClick}
										/>
									);
								})}
						</div>
						<div className="row footer"></div>
					</div>
					<Dialog
						open={showModal}
						// disableBackdropClick
						disableEscapeKeyDown
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">
							Hurray!!! You completed the challenge
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								You completed the game in {moves} moves. Your best score is{' '}
								{bestScore} moves.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleRestart} color="primary">
								Restart
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			</div>
		</CSSTransition>
	);
}
