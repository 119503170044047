import React, { useEffect, useRef, useState } from 'react';
import { guid } from '../../utils/guid';
import usePlayer from '../../hooks/usePlayer';

interface ICurrencyFloater {
	amount: number;
	id: string;
	onDelete?: (floater:ICurrencyFloater) => void,
}

function CurrencyFloater(props: ICurrencyFloater) {
	useEffect(() => {
		const to = setTimeout(() => {
			props.onDelete?.(props);
		}, 200000);

		return () => clearTimeout(to);
	}, []);

	return (
		<div className="xp-floater">+{props.amount} CO</div>
	);
}

export default function XpFloaters() {
	const last = useRef<number | null>(null);
	const { CO: co } = usePlayer()?.currencies || { CO: 0 };

	const [floaters, setFloaters] = useState<ICurrencyFloater[]>([]);

	function onDelete(floater: ICurrencyFloater) {
		setFloaters(floaters.filter(f => f.id !== floater.id));
	}

	useEffect(() => {
		if (last.current === null) last.current = co;

		if (co !== last.current) {
			const diff = co - last.current;

			if (diff > 0) {
				setFloaters(floaters.concat({ amount: diff, id: guid() }));
			}

			last.current = co;
		}
	}, [co, last]);

	return (
		<div className="xp-floaters">
			{floaters.map(f => <CurrencyFloater key={f.id} {...f} onDelete={onDelete} />)}
		</div>
	);
}