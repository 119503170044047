import { createSlice } from '@reduxjs/toolkit';
import { getXrApi } from '../api/apiBridge';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';

const initialState = {
	isFetchingPredictions: false,
	forceFetchPredictions: false,
	predictions: [] as Partial<XRPredictionInstanceItem>[],
	predictionData: {} as Record<string, XRPredictionInstanceData>,
	unclaimedPredictions: [] as string[],
	active_prediction: null as Partial<XRPredictionInstanceItem>,
};

type IPredictionsState = typeof initialState;

function toPrediction(eventPrediction:EventPrediction):Partial<XRPredictionInstanceItem> {
	return {
		instanceId: eventPrediction.PredictionInstance,
		matchId: eventPrediction.PredictionMatchId,
		timestamp: new Date(eventPrediction.PredictionTimestamp).getTime(),
		voteExpiration: eventPrediction.PredictionVoteExpiration,
		jsonUrl: eventPrediction.PredictionUrl,
	};
}

export const fetchPredictions = createDebouncedAsyncAction<IPredictionsState>(
	'predictions/fetchPredictions',
	(data: { InstanceId?: string }) => {
		const api = getXrApi();
		return api.Client.GetPredictionInstances(data) || Promise.reject('No API');
	},
);

export const fetchPredictionData = createDebouncedAsyncAction<IPredictionsState>(
	'predictions/fetchPredictionData',
	(data: { url: string }) => {
		return fetch(data.url).then(res => res.json()).then(json => json);
	},
);

export const answerPredictions = createDebouncedAsyncAction<IPredictionsState>(
	'predictions/answerPredictions',
	(data: { InstanceId: string, Answers: string | Record<string, string | Record<string, string | number>>, TimeToAnswer?: number }) => {
		const api = getXrApi();
		return api.Client.AnswerPrediction(data) || Promise.reject('No API');
	},
);

export const fetchUnclaimedPredictions = createDebouncedAsyncAction<IPredictionsState>(
	'predictions/fetchUnclaimedPredictions',
	() => {
		const api = getXrApi();
		return api.Client.GetUnclaimedPredictions() || Promise.reject('No API');
	},
);


export const claimPredictionRewards = createDebouncedAsyncAction<IPredictionsState>(
	'predictions/claimPredictionRewards',
	(data: { InstanceId: string }) => {
		const api = getXrApi();
		return api.Client.ClaimPredictionRewards(data) || Promise.reject('No API');
	},
);



const predictions = createSlice({
	name: 'predictions',
	reducers: {
		setActivePrediction: (state, action: { type: string, payload: EventPrediction }) => {
			const formattedEventPrediction = toPrediction(action.payload);
			state.active_prediction = formattedEventPrediction;
			
			const prediction = state.predictions.find(p => p.instanceId === formattedEventPrediction.instanceId);
			if (!prediction) {
				state.predictions.push(formattedEventPrediction as XRPredictionInstanceItem);
			}
		},

		setPredictionResult: (state, action: { type: string, payload: { instanceId: string, result: XRPredictionAnswers } }) => {
			const { instanceId, result } = action.payload;
			const prediction = state.predictions.find(p => p.instanceId === instanceId);
			if (prediction) {
				prediction.result = result;
				state.predictions[state.predictions.indexOf(prediction)] = prediction;
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPredictionData.actions.fulfilled, (state, action) => {
			const { payload } = action;
			state.predictionData[payload.id] = { ...payload };
			return state;
		});

		builder.addCase(fetchPredictions.actions.pending, (state) => {
			state.isFetchingPredictions = true;
		});
		builder.addCase(fetchPredictions.actions.fulfilled, (state, action: { type: string, payload: any }) => {
			state.forceFetchPredictions = false;
			state.predictions = action.payload.data.PredictionInstances;
		});

		builder.addCase(fetchUnclaimedPredictions.actions.fulfilled, (state, action: { type: string, payload: any }) => {
			state.unclaimedPredictions = action.payload.data.UnclaimedPredictionInstanceIds;
		});

		builder.addCase(answerPredictions.actions.fulfilled, (state, action: { type: string, payload: any }) => {
			const idx = state.predictions.findIndex(p => p.instanceId === action.payload.params.InstanceId);
			state.predictions[idx] = {
				...state.predictions[idx],
				isAnswered: true,
			};
		});

		builder.addCase(claimPredictionRewards.actions.fulfilled, (state) => {
			state.forceFetchPredictions = true;
		});
	},
	initialState,
});

export default predictions;

export const { setActivePrediction, setPredictionResult } = predictions.actions;