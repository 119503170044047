import React, { CSSProperties } from 'react';

export default function CircleProgress({ progress = 0 }: { progress: number }) {
	const styles = {
		'--progress': progress * 100,
	} as CSSProperties;

	return (
		<svg className="circle-progress" style={styles}>
			<circle className="circle-progress-track" />
			<circle className="circle-progress-indication" />
		</svg>
	);
}