import { useAppSelector } from '../../../redux/config/store';
import useRewardTracks from './useRewardTracks';

export default function useRewardTrack(trackId:string, thresholds:number[], statOverride:string = null) {
	const track = useRewardTracks().find(t => t.id === trackId);

	const iterator = thresholds || Array.from(track.tiers.reduce((c, t) => {
		c.add(t.threshold);
		return c;
	}, new Set<number>()));
	
	const stat = useAppSelector(state => state.statistics[statOverride || track.statisticName]);

	return {
		track,
		iterator,
		stat,
	};
}