import { ITEM_CLASSES } from '../Constants';
import { useAppSelector } from '../redux/config/store';
import useCatalog from './useCatalog';

export default function useMemoryGameTheme() {
	const catalog = useCatalog().items.filter(c => c.type.title === ITEM_CLASSES.MEMORY_GAME_THEME);
	const inventory = useAppSelector(state => state.inventory).filter(i => i.type.title === ITEM_CLASSES.MEMORY_GAME_THEME);

	inventory.sort((a, b) => {
		return new Date(b.playfab.Expiration).getTime() - new Date(a.playfab.Expiration).getTime();
	});

	const theme = inventory[0] || catalog.find(i => i.data.is_default);

	return theme;
}