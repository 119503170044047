import catalog from './catalog';
import chat from './chat';
import env from './env';
import globalVariables from './globalVariables';
import inventory from './inventory';
import missions from './missions';
import notifications from './notifications';
import otherPlayers from './otherPlayers';
import playfab from './playfab';
import realtime from './realtime';
import statistics from './statistics';
import title_data from './title_data';
import xr_store from './xr_store';
import playerData from './playerData';
import loadingScreen from './loadingScreen';
import rfid from './rfid';
import ads from './ads';
import polls from './polls';
import quizzes from './quizzes';
import predictions from './predictions';

export const rootReducer = {
	env: env.reducer,
	playfab: playfab.reducer,
	globalVariables: globalVariables.reducer,
	catalog: catalog.reducer,
	inventory: inventory.reducer,
	realtime: realtime.reducer,
	statistics: statistics.reducer,
	titleData: title_data.reducer,
	xrStore: xr_store.reducer,
	notifications: notifications.reducer,
	missions: missions.reducer,
	chat: chat.reducer,
	otherPlayers: otherPlayers.reducer,
	playerData: playerData.reducer,
	loadingScreen: loadingScreen.reducer,
	rfid: rfid.reducer,
	ads: ads.reducer,
	polls: polls.reducer,
	quizzes: quizzes.reducer,
	predictions: predictions.reducer,
};
