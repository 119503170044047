/* eslint-disable space-before-function-paren */
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { answerPoll, getPolls } from '../redux/polls';
import { updatePlayerData } from '../redux/playerData';
import usePlayer from './usePlayer';
import { PayloadAction } from '@reduxjs/toolkit';

export default function usePolls() {
	const {
		polls,
		votes,
		results,
		isLoaded,
	} = useAppSelector(state => state.polls);

	const { playerId } = usePlayer();

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!isLoaded && playerId) {
			dispatch(getPolls());
		}
	}, [isLoaded]);

	const { pollAnswers: answers } = useAppSelector(state => state.playerData?.data || {});

	return {
		polls,
		votes,
		results,
		answers,

		answerPoll: async (poll:XRPoll, choiceId:string) => {
			const resp:PayloadAction<GenericApiCallResponse<AnswerPollResponse>> = await dispatch(answerPoll({ InstanceId: poll.instanceId, AnswerId: choiceId }));
			const correctAnswer = resp.payload.data.PlayerVote.Success ? choiceId : resp.payload.data.PlayerVote.RightAnswers[0].id;
			await dispatch(updatePlayerData({ pollAnswers: { ...answers, [poll.instanceId]: { choice: choiceId, correctAnswer } } }));
			return resp;
		},
		refresh: () => dispatch(getPolls()),
	};
}