import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import { getJSON } from '../api/api';
import { DEFAULT_LANG, ROUTES, CDN_BASE } from '../Constants';
import { getQueryParameterByName } from '../utils/urlUtils';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';

type IEnvState = typeof initialState;

export enum LoginMethods {
	CUSTOM_ID = 'custom_id',
	RFID = 'rfid',
}

export const incrementErrors = createAction('env/error');

export const getTexts = createDebouncedAsyncAction<IEnvState>(
	'env/getTexts',
	() => {
		return getJSON(CDN_BASE + '/assets/texts.json?v=' + Date.now());
	},
);

export const getLoginPageContent = createDebouncedAsyncAction<IEnvState>(
	'env/getLoginPageContent',
	() => {
		return getJSON(CDN_BASE + '/content/LoginPage.json?v=' + Date.now());
	},
);

export const getAppData = createDebouncedAsyncAction<IEnvState>(
	'env/getAppDataContent',
	() => {
		return getJSON(CDN_BASE + 'content/App.json?v=' + Date.now());
	},
);

export const getHelp = createDebouncedAsyncAction<IEnvState>(
	'env/getHelp',
	() => {
		return getJSON(CDN_BASE + 'assets/help.json?v=' + Date.now());
	},
);

type LoginPage = {
	SidebarTitle: string,
	BackgroundImage: string,
	SectionTitle: string,
	ExistingUserAvatar: string,
	ExistingUserGreet: string,
	NewUser: string,
	QrMessage: string,
};

type AppData = {
	Logo: string,
};

const initialState = {
	Lang: getQueryParameterByName('lang') || DEFAULT_LANG,
	LastPath: ROUTES.ROOT,
	Errors: [],
	Texts: null as Record<string, any> | null,
	LoginPageContent: null as LoginPage | null,
	Help: null,
	App: null as AppData | null,
	isLoggingIn: false,
	loggingMethod: null as LoginMethods,
	background: null as string,
};

const env = createSlice({
	name: 'env',
	reducers: {
		setLastPath: (state, action:PayloadAction<string>) => { state.LastPath = action.payload; },
		setIsLoggingIn: (state, action:PayloadAction<boolean>) => {
			state.isLoggingIn = action.payload;
		},
		setLoggingMethod: (state, action:PayloadAction<LoginMethods>) => { state.loggingMethod = action.payload; },
		setBackground: (state, action:PayloadAction<string>) => { state.background = action.payload; },
	},
	extraReducers: (builder) => {
		builder.addCase(getTexts.actions.fulfilled, (state, action) => {
			state.Texts = action.payload;
		});

		builder.addCase(getLoginPageContent.actions.fulfilled, (state, action) => {
			state.LoginPageContent = action.payload;
		});

		builder.addCase(getAppData.actions.fulfilled, (state, action) => {
			state.App = action.payload;
		});

		builder.addCase(getHelp.actions.fulfilled, (state, action) => {
			state.Help = action.payload;
		});

		builder.addCase(incrementErrors, (state) => {
			state.Errors = [
				...state.Errors,
				Date.now(),
			];
		});
	},
	initialState,
});

export default env;

export const { setLastPath, setIsLoggingIn, setLoggingMethod, setBackground } = env.actions;
