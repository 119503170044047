import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { ROUTES } from '../../Constants';
import useMemoryGameTheme from '../../hooks/useMemoryGameTheme';
import useCityBackground from '../../hooks/useCityBackground';

export default function MiniGames() {
	const page = useRef<HTMLDivElement>(null);

	const themeMemory = useMemoryGameTheme();

	const cardMemoryImage = themeMemory?.data.card_image;

	useCityBackground();

	return (
		<CSSTransition
			nodeRef={page}
			key="mini-games"
			timeout={1200}
			in={true}
			appear
		>
			<div className="page challenges mini-games" ref={page}>
				<div className="page-content">
					<div className="page-title">Mini Games</div>

					<div className="challenges-ctn">
						<NavLink
							className="challenge-card"
							to={ROUTES.ROOT + ROUTES.MEMORY}
						>
							<div className="title">
								Memory <br />
								Game
							</div>
							<div className="button">Play</div>
							<div className="description" />
							<div className="bg">
								<img src={cardMemoryImage as string} alt="" />
							</div>
						</NavLink>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
}
