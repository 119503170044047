import React, { memo, useEffect, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangle } from '@fortawesome/sharp-solid-svg-icons';

import useGlobalVariables from '../../hooks/useGlobalVariables';
import useXRStore from '../../hooks/useXRStore';
import { raw } from '../../utils/textUtils';
import Button from '../ui/Button';
import Loading from '../ui/Loading';
import useBackground from '../../hooks/useBackground';
import useClickOutsideOf from '../../hooks/useClickOutsideOf';
import useStats from '../../hooks/useStats';

function Store() {
	const { isLoaded } = useGlobalVariables();
	const page = useRef<HTMLDivElement>(null);
	const sidebar = useRef<HTMLDivElement>(null);
	const categoriesList = useRef<HTMLUListElement>(null);

	const {
		StorePage,
		IsReady,
		ActiveCategory,
		setActiveCategoryId,
		Products,
		isBrandCurated,
	} = useXRStore();

	const [filtersListOpened, setFiltersListOpened] = useState<boolean>(false);

	const { favorite_brand: favoriteBrand } = useStats();

	const { setBackground } = useBackground();

	useEffect(() => {
		const brandBg = StorePage?.BackgroundImage?.[`brand_${favoriteBrand}`];
		const defaultBg = StorePage?.BackgroundImage?.default;

		if (isBrandCurated) {
			setBackground(brandBg || defaultBg || '');
		} else {
			setBackground(defaultBg || '');
		}
	}, [StorePage, isBrandCurated]);

	useClickOutsideOf({
		enabled: filtersListOpened,
		targetSelector: '[data-categories-list]',
		onInteractOutside: () => setFiltersListOpened(false),
	});

	if (!isLoaded || !IsReady || !StorePage) {
		return (
			<div className="page store" ref={page}>
				<Loading isLoading={isLoaded} />
			</div>
		);
	}

	const selectCategory = (categoryId: number) => {
		return () => setActiveCategoryId(categoryId);
	};

	return (
		<CSSTransition nodeRef={page} key="store" timeout={2200} in={true} appear>
			<div className="page store" ref={page}>
				<div className="bgs"></div>
				<div className="page-content">
					<div className="slide active">
						<div className="content">
							<div className="title" {...raw(StorePage.Title)} />
							<div className="text" {...raw(StorePage.Text)} />
							<Button className="big" label={StorePage.ButtonLabel} hasArrow />
						</div>
					</div>
				</div>

				<CSSTransition nodeRef={sidebar} timeout={1200} in={true} appear>
					<div className="sidebar" ref={sidebar}>
						<div className="store-section">
							<div className="store-head">
								<div className="title">
									<b>{StorePage.SidebarTitle}</b>
								</div>
								<div className="categories-filter">
									<div className="categories-label">{StorePage.CategoriesLabel}</div>
									<button className={`toggle-btn ${filtersListOpened ? 'expanded' : ''}`} onClick={() => setFiltersListOpened(prev => !prev)}>
										{ActiveCategory?.Name}
										<FontAwesomeIcon icon={faTriangle} />
									</button>
									<CSSTransition nodeRef={categoriesList} timeout={1000} in={filtersListOpened}>
										<ul className="categories-list" data-categories-list ref={categoriesList}>
											{StorePage.StoreCategories.map((category) => (
												<li key={category.SectionId}>
													<button className={ActiveCategory.SectionId === category.SectionId ? 'active' : ''} onClick={selectCategory(category.SectionId)}>{category.Name}</button>
												</li>
											))}
										</ul>
									</CSSTransition>
								</div>
							</div>
							<div className="products">
								<TransitionGroup component={null}>
									{Products.map(({ tile, item }) => {
										return (
											<CSSTransition key={tile.id} timeout={500}>
												<div className="product">
													{item.data.Image && <div className="image">
														<img src={item.data.Image.toString()} alt="" />
													</div>}
													<div className="name" {...raw(item.playfab.DisplayName)} />
													<div className="variations" {...raw(item.data.Variations.toString())} />
													<div className="price">
														{tile.discountPrice !== tile.regularPrice && (
															<div className="sale-price" {...raw(`$${tile.discountPrice}`)} />
														)}
														<div className="regular-price" {...raw(`$${tile.regularPrice}`)} />
													</div>
												</div>
											</CSSTransition>
										);
									})}
								</TransitionGroup>
							</div>
						</div>
					</div>
				</CSSTransition>
			</div>
		</CSSTransition>
	);
}

export default memo(Store);
