import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IRootState, useAppDispatch } from '../redux/config/store';
import { getMissionInventory } from '../redux/missions';
import usePlayer from './usePlayer';


export default function useMissions(filter:string = null) {
	const missions = useSelector((state:IRootState) => state.missions.list);
	const { playerId } = usePlayer();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (missions.length === 0 && playerId) {
			dispatch(getMissionInventory());
		}
	}, [missions, playerId]);

	if (!filter) return missions;
	return missions.filter(mission => mission.type.title === filter);
}