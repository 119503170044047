import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ITEM_CLASSES } from '../Constants';
import { IRootState, useAppDispatch } from '../redux/config/store';
import { writePlayerEvent } from '../redux/playfab';
import { updateLocalStatistic } from '../redux/statistics';

export default function useBrands() {
	const dispatch = useAppDispatch();
	const favoriteBrand = useSelector((state:IRootState) => state.statistics.favorite_brand);
	const brands = useSelector((state:IRootState) => state.catalog.items.filter(item => item.playfab.ItemClass === ITEM_CLASSES.BRAND));

	function setFavoriteBrand(brandId:number) {
		if (favoriteBrand === brandId) return;

		dispatch(writePlayerEvent({
			name: 'set_favorite_brand',
			body: {
				brand_id: brandId,
			},
		}));

		dispatch(updateLocalStatistic({
			name: 'favorite_brand',
			value: brandId,
		}));
	}

	return {
		brands: useMemo(() => {
			const arr = brands.map((brand) => {
				const t = JSON.parse(JSON.stringify(brand)) as IXRInventoryItemParsedData;
				t.data.IsFavorite = brand.data.BrandId === favoriteBrand;
				return t;
			});

			arr.sort((a, b) => (a.data.BrandId  as number) - (b.data.BrandId as number));

			return arr;
		}, [brands, favoriteBrand]),
		hasFavoriteBrand: favoriteBrand !== 0,
		favoriteBrand: favoriteBrand !== 0 && brands.find(team => team.data.BrandId === favoriteBrand),
		setFavoriteBrand: setFavoriteBrand,
	};
}