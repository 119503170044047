import { useCallback, useRef } from 'react';

type Callback<A extends unknown[], R> = (...args: A) => R;

export default function useEventCallback<Args extends unknown[], R>(callback?: Callback<Args, R>) {
	const callbackRef = useRef<Callback<Args, R>>();
	callbackRef.current = callback;

	const memoizedCallback = useCallback((...args: Args) => callbackRef.current?.(...args), []);
	return memoizedCallback;
}
