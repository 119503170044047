import React from 'react';
import { raw } from '../../utils/textUtils';

export const LinedTitleTypes = {
	SMALL: 'small',
	REGULAR: 'regular',
	LARGE: 'large',
};

type LinedTitleProps = {
	text:string,
	size?:string,
	caps?:boolean
	className?:string,
};

export default function LinedTitle({ className = '', text = '', size = LinedTitleTypes.REGULAR, caps = false }:LinedTitleProps) {
	return (
		<div className={`lined-title ${size} ${caps ? 'caps' : ''} ${className}`}>
			<div className="text" {...raw(text)} />
		</div>
	);
}