import { useEffect, useRef } from 'react';
import useEventCallback from './useEventCallback';

const ACTIVITY_EVENTS = ['pointerdown', 'mousedown', 'touchstart'];
const END_ACTIVITY_EVENTS = ['pointerout', 'mouseup', 'touchend', 'touchcancel'];

type TimeoutCallback = () => void;

export const useInactivity = ({
	element: pElement,
	activityEvents: eventsFilter,
	endActivityEvents: endEventsFilter,
	timeout,
	onTimeout,
	onActivity,
	enabled,
	repeat,
}:{
	element?: Element,
	activityEvents?: (defaultEvents: string[]) => string[];
	endActivityEvents?: (defaultEvents: string[]) => string[];
	timeout: number;
	onTimeout?: TimeoutCallback;
	onActivity?: TimeoutCallback;
	enabled?: boolean;
	repeat?: boolean;
}) => {
	const element = pElement ?? document.body;
	const events = eventsFilter?.(ACTIVITY_EVENTS) ?? ACTIVITY_EVENTS;
	const endEvents = endEventsFilter?.(END_ACTIVITY_EVENTS) ?? END_ACTIVITY_EVENTS;

	const inactivityTimeout = useRef<NodeJS.Timeout>();
	const isInteracting = useRef<boolean>(false);

	const onTimeoutCallback = useEventCallback(onTimeout);
	const onActivityCallback = useEventCallback(onActivity);

	useEffect(() => {
		const startTimeout = () => {
			if (isInteracting.current) return;

			inactivityTimeout.current = setTimeout(() => {
				onTimeoutCallback();
				if (repeat) {
					startTimeout();
				}
			}, timeout);
		};

		const interactionStart = () => {
			if (isInteracting.current) return;

			isInteracting.current = true;
			clearTimeout(inactivityTimeout.current);
			onActivityCallback();
		};

		const interactionEnd = () => {
			if (!isInteracting.current) return;

			isInteracting.current = false;
			startTimeout();
		};

		const abortController = new AbortController();

		const setupEvents = () => {
			const { signal } = abortController;
			events.forEach((event) => {
				element.addEventListener(event, interactionStart, { capture: true, signal });
			});
			endEvents.forEach((event) => {
				element.addEventListener(event, interactionEnd, { signal });
			});
		};

		const cleanup = () => {
			abortController.abort();
			clearTimeout(inactivityTimeout.current);
		};

		if (!enabled) {
			return cleanup;
		}

		setupEvents();
		startTimeout();

		return cleanup;
	}, [
		enabled,
		timeout,
	]);
};
