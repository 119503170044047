import React from 'react';
import classnames from 'classnames';
import './card.scss';
import useMemoryGameTheme from '../../../../../hooks/useMemoryGameTheme';

const Card = ({
	onClick,
	card,
	index,
	isInactive,
	isFlipped,
	isDisabled,
}: {
	onClick: (index: number) => void;
	card: { type: string; image: string };
	index: number;
	isInactive: boolean;
	isFlipped: boolean;
	isDisabled: boolean;
}) => {
	const data = useMemoryGameTheme()?.data;
	const handleClick = () => {
		return !isFlipped && !isInactive && !isDisabled && onClick(index);
	};

	return (
		card?.image &&
		data && (
			<div
				className={classnames('col-2 card', {
					'is-flipped': isFlipped,
					'is-inactive': isInactive,
				})}
				onClick={handleClick}
			>
				<div className="card-face card-font-face p-2">
					<img src={data.card_back as string} alt="card back" />
				</div>
				<div className="card-face card-back-face p-2">
					<img src={card.image} alt="card front" />
				</div>
			</div>
		)
	);
};

export default Card;
