import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getXrApi } from '../api/apiBridge';
import { XRAdActivityTypes } from '../api/enums';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';
import xrAction from './async/xrAction';

export const getAdPlacements = createDebouncedAsyncAction(
	'playfab-xr/getAdPlacements',
	xrAction(() => {
		return getXrApi().Client.GetAdPlacements();
	}),
);

export const getSingleAdPlacement = createDebouncedAsyncAction(
	'playfab-xr/getSingleAdPlacement',
	xrAction((PlacementId:string) => {
		return getXrApi().Client.GetAdPlacements({ PlacementId });
	}),
);

export const skipAdPlacement = createDebouncedAsyncAction(
	'playfab-xr/skipAdPlacement',
	xrAction(({ PlacementId, CurrencyCode }:{ PlacementId: string, CurrencyCode:string }) => {
		return getXrApi().Client.SkipAdPlacement({ PlacementId, CurrencyCode });
	}),
);

export const reportAdActivity = createDebouncedAsyncAction(
	'playfab-xr/reportAdActivity',
	xrAction(({ PlacementId, Activity }: { PlacementId:string, Activity:XRAdActivityTypes }) => {
		return getXrApi().Client.ReportAdActivity({ PlacementId, Activity });
	}),
);

const ads = createSlice({
	name: 'ads',
	initialState: {

	} as Record<string, XRAdPlacement>,
	reducers: {
		updateLocalAd: (state, action:PayloadAction<{ placementId: string, ad:XRAdPlacement }>) => {
			return {
				...state,
				[action.payload.placementId]: action.payload.ad,
			};
		},
		removeAd: (state, action:PayloadAction<{ placementId: string }>) => {
			const { [action.payload.placementId]: old, ...rest } = state;
			return rest;
		},
		resetAds: () => {
			return {};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getAdPlacements.actions.fulfilled, (state, action) => {
			const placements = action.payload.data?.AdPlacements.reduce((acc, placement) => {
				acc[placement.data.PlacementId] = placement;
				return acc;
			}, {} as Record<string, XRAdPlacement>);

			return {
				...state,
				...placements,
			};
		});
		builder.addCase(getSingleAdPlacement.actions.fulfilled, (state, action) => {
			const placement = action.payload.data?.AdPlacement;

			return {
				...state,
				[placement.data.PlacementId]: placement,
			};
		});
		builder.addCase(reportAdActivity.actions.fulfilled, () => {});
		builder.addCase(skipAdPlacement.actions.fulfilled, () => {});
	},
});

export default ads;

export const { updateLocalAd, resetAds, removeAd } = ads.actions;