import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { LoginMethods, setIsLoggingIn, setLoggingMethod } from '../redux/env';
import { addGenericId, authPlayfab, getPlayFabIDsFromGenericIDs } from '../redux/playfab';
import { clearRFIDTag } from '../redux/rfid';
import { guid } from '../utils/guid';
import useEnv from './useEnv';
import { useOtherPlayerProfile } from './useOtherPlayer';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../Constants';

const GENERIC_ID_SERVICE_NAME = 'demo_app';

function formatTag(tag:string) {
	return tag.replace(/[^a-z0-9]/gi, '_');
}

export default function useLogin() {
	const dispatch = useAppDispatch();
	const { isLoggingIn, loggingMethod } = useAppSelector(state => state.env);
	const latestTag = useAppSelector(state => state.rfid.latestTag);
	const [tagPlayFabId, setTagPlayFabId] = useState<string | null>(null);
	const navigate = useNavigate();

	const playerId = useAppSelector(state => state.playfab.PlayFabId);

	const { LoginPageContent } = useEnv();

	async function auth(str:string, method:LoginMethods) {
		dispatch(setIsLoggingIn(true));
		dispatch(setLoggingMethod(method));
		await dispatch(authPlayfab({ userId: str })).then(() => {
			dispatch(setIsLoggingIn(false));
			dispatch(setLoggingMethod(null));
			dispatch(addGenericId({
				UserId: str,
				ServiceName: GENERIC_ID_SERVICE_NAME,
			}));
		});
	}

	function OnClickExistingUser() {
		auth('chris', LoginMethods.CUSTOM_ID);
	}

	function OnClickNewUser() {
		auth(guid(), LoginMethods.CUSTOM_ID);
	}

	async function OnRFID(tag:string) {
		await auth('rfid_' + formatTag(tag), LoginMethods.RFID);
		dispatch(clearRFIDTag());
		navigate(ROUTES.PROFILE);
	}

	useEffect(() => {
		if (latestTag && playerId) {
			dispatch(getPlayFabIDsFromGenericIDs([{
				UserId: formatTag('rfid_' + latestTag),
				ServiceName: GENERIC_ID_SERVICE_NAME,
			}])).then((data) => {
				const pfid = data.payload.data.Data?.[0]?.PlayFabId;
				if (pfid) {
					setTagPlayFabId(pfid);
				} else {
					setTagPlayFabId(null);
				}
			});
		}
	}, [latestTag, playerId]);
	
	const otherPlayer = useOtherPlayerProfile(tagPlayFabId);

	return {
		...LoginPageContent,
		loggingMethod,
		IsLoggingIn: isLoggingIn,
		serviceName: GENERIC_ID_SERVICE_NAME,
		tagDisplayName: otherPlayer?.DisplayName,

		OnRFID,
		OnClickExistingUser,
		OnClickNewUser,
	};
}