import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';
import { getXrApi } from '../api/apiBridge';

export type XRQuizAnswerRewards = {
	statistics: Record<string, number>,
	items: IPlayfabItemInstance[],
};

interface XRQuizChoiceStatisticsWithHighest extends XRQuizChoiceStatistics {
	isHighest: boolean,
}

interface XRQuizQuestionStatisticsWithHighest extends XRQuizQuestionStatistics {
	result: XRQuizChoiceStatisticsWithHighest[],
}

export type XRQuizInstance = Omit<XRQuizInstanceItem, 'rewards'> & {
	customData: Record<string, any>,
	questions: XRQuizInstanceQuestion[],
	rewards: XRGrantedRewards,
	answers: Record<string, string | boolean>[],
	statistics: XRQuizQuestionStatisticsWithHighest[],
	result: XRQuizAnswerResult[],
};


const defaultState = {
	instances: [] as XRQuizInstance[],
	focusedInstance: null as string | null,
};

export function quizInstanceFromInstantionEvent(data: Record<string, any>): XRQuizInstance {
	return {
		instanceId: data?.QuizInstanceId as string,
		matchId: data?.QuizMatchId as string,
		type: data?.QuizData?.type as string,
		displayName: data?.QuizData?.name as string,
		timestamp: data?.QuizData?.timestamp,
		voteExpiration: data?.QuizData?.voteExpiration,
		totalExpiration: data?.QuizData?.totalExpiration,
		customData: data?.QuizData?.customData,
		questions: data?.QuizData?.questions,
		jsonUrl: data?.QuizUrl,
		isResolved: false,
		isAnswered: false,
		segmentId: null,
		playerId: null,
		rewards: null,
		result: null,
		answers: [],
		statistics: [],
	};
}

export const answerQuiz = createDebouncedAsyncAction(
	'quizzes/answerQuiz',
	(data: { InstanceId: string, Answers: string | Record<string, string | Record<string, string | number>>, type: string, TimeToAnswer?: number, }) => {
		const api = getXrApi();

		let endpoint;
		switch (data.type) {
			case 'trivia':
				endpoint = api.Client.AnswerTrivia;
				break;
			case 'survey':
				endpoint = api.Client.AnswerSurvey;
				break;
			case 'prediction':
				endpoint = api.Client.AnswerPrediction;
				break;
			default:
				return Promise.reject('Invalid type');
		}

		return endpoint(data) as Promise<GenericApiCallResponse<AnswerQuizResponse>> || Promise.reject('No API');
	},
);

export const getQuizInstanceStatistics = createDebouncedAsyncAction(
	'quizzes/getQuizInstanceStatistics',
	(data: { InstanceId: string }) => {
		const api = getXrApi();
		return api.Client.GetQuizInstanceStatistics(data) || Promise.reject('No API');
	},
);

const quizzes = createSlice({
	name: 'quizzes',
	initialState: defaultState,
	reducers: {
		addQuizInstance: (state, action: PayloadAction<XRQuizInstance>) => {
			const instance = action.payload;
			// console.log('addQuizInstance', instance);
			state.instances = [
				...state.instances.filter(p => p.instanceId !== instance.instanceId),
				instance,
			];
		},
		focusInstance: (state, action: PayloadAction<string>) => {
			state.focusedInstance = action.payload;
		},
		unfocusInstance: (state) => {
			state.focusedInstance = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(answerQuiz.actions.fulfilled, (state, action) => {

			const instance = state.instances.find(p => p.instanceId === action.payload.params.InstanceId);
			if (!instance) return;

			instance.isAnswered = true;
			// instance.result = action.payload.data.Answers;
			instance.rewards = action.payload.data.Rewards;
			instance.answers = Object.values(action.payload.data.Answers).map((answer: Record<string, any>) => ({
				...answer,
				isCorrect: answer.correctAnswer === answer.userAnswer,
			}));

			state.instances = state.instances.map(p => p.instanceId === instance.instanceId ? instance : p);
		});

		builder.addCase(getQuizInstanceStatistics.actions.fulfilled, (state, action) => {
			// @ts-ignore
			const instance = state.instances.find(p => p.instanceId === action.payload.params.InstanceId);

			const statistics = action.payload.data.Statistics.map((stat: XRQuizQuestionStatistics) => {
				const result = stat.result;
				let highest = { code: '', percent: 0 };
				
				result.forEach((r) => {
					if (r.percent > highest.percent) highest = { code: r.code, percent: r.percent };
				});

				return {
					...stat,
					result: result.map(r => ({ 
						...r,
						isHighest: r.code === highest.code,
					})),
				};
			});
				
			instance.statistics = statistics;
			state.instances = state.instances.map(p => p.instanceId === instance.instanceId ? instance : p);
		});	
	},
});

export default quizzes;

export const { addQuizInstance, focusInstance, unfocusInstance } = quizzes.actions;