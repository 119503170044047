import { faBan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, memo, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useOtherPlayerProfile } from '../../hooks/useOtherPlayer';
import useBrands from '../../hooks/useBrands';
import type { ChatMessage } from '../../redux/chat';
import LoadingSpinner from '../icons/LoadingSpinner';
import usePlayer from '../../hooks/usePlayer';

type MessageProps = {
	message: ChatMessage;
	onHover?:({ message, y }:{ message: ChatMessage, y: number }) => void;
	onHoverOut?:() => void;
};

function Message({ message, onHover, onHoverOut }:MessageProps) {
	const { AvatarUrl, Statistics } = useOtherPlayerProfile(message.PlayFabId) || {};
	const { playerId } = usePlayer();

	const ref = useRef<HTMLDivElement>(null);

	const teamId = (Statistics || []).find(s => s.Name === 'favorite_team')?.Value || 0;
	const { brands: teams } = useBrands();
	const favoriteTeam = teams.find(t => t.data.TeamId === teamId);
	const color = favoriteTeam?.data.Color || '';

	const style = {
		'--color': color,
	} as CSSProperties;

	const isMessageByCurrentUser = playerId === message.PlayFabId;

	return (
		<CSSTransition in={true} appear nodeRef={ref} timeout={{ appear: 1200, enter: 1200, exit: 0 }}>
			<div className={`message ${message.Payload.IsModerated ? 'moderated' : ''} ${isMessageByCurrentUser ? 'current-user' : 'other-user'}`} title={message.Payload.IsModerated ? 'This comment was moderated' : ''} ref={ref}>
				<div className="message-inner" style={style} onMouseEnter={() => onHover?.({ message, y: ref.current.getBoundingClientRect().top })} onMouseLeave={() => onHoverOut?.()}>
					{message.Payload.IsModerated && <span><FontAwesomeIcon icon={faBan} /> </span>}
					<div className="avatar">
						<div className="image-ctn">
							{AvatarUrl && <img src={AvatarUrl} alt="" />}
							{!AvatarUrl && <LoadingSpinner className="spinner" />}
						</div>
					</div>
					<span className="username">{isMessageByCurrentUser ? 'You' : message.DisplayName}</span>
					<span className="message-content">{message.Payload.Message}</span>
				</div>
			</div>
		</CSSTransition>
	);
}

export default memo(Message);
