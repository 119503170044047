import { getXrApi } from '../api/apiBridge';

export function useLogout() {
	function Logout() {
		getXrApi().Auth.Logout().then(() => window.location.reload());
	}

	return {
		Logout,
	};
}