/* eslint-disable @typescript-eslint/naming-convention */
import { useAppSelector } from '../redux/config/store';
import useGlobalVariables from './useGlobalVariables';

export default function useXP() {
	const {
		xp,
	} = useAppSelector(state => state.statistics);

	const {
		CompoundStatsConfig,
	} = useGlobalVariables();

	const xpLevels = CompoundStatsConfig?.find?.(m => m.source.name === 'xp' && m.target.name === 'level')?.source.mapping ?? [];

	const xpLevelsReversed = [...xpLevels].reverse();

	const currentLevelXp = xpLevelsReversed.find(levelXp => levelXp <= xp) ?? 0;
	const nextLevelXp = xpLevels.find(levelXp => levelXp > xp) ?? 0;
	const level = xpLevels.indexOf(currentLevelXp) + 2;
	const progress = (xp - currentLevelXp) / (nextLevelXp - currentLevelXp);

	return {
		xp,
		nextLevelXp,
		currentLevelXp,
		level,
		progress,
		xpLevels,
	};
}