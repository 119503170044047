import React, { memo, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import 'videojs-overlay';
import '@videojs/http-streaming';
import { NavLink } from 'react-router-dom';

import { ROUTES } from '../../Constants';
import useGlobalVariables from '../../hooks/useGlobalVariables';
import useMatchAnalytics from '../../hooks/useMatchAnalytics';
import ChatBox from '../chat/ChatBox';
import useBackground from '../../hooks/useBackground';

function HighlightMatch() {
	const page = useRef<HTMLDivElement>(null);

	const {
		MatchPage,
		AppSettings,
	} = useGlobalVariables();

	const {
		BackgroundImage,
	} = MatchPage || {};

	const { setBackground } = useBackground();
	useEffect(() => {
		setBackground(BackgroundImage);
	});

	const {
	} = MatchPage || {};

	useMatchAnalytics();

	return MatchPage && (
		<CSSTransition nodeRef={page} key="match" timeout={1200} in={true} appear>
			<div className="page match" ref={page}>
				<div className="bg"></div>
				{<NavLink to={ROUTES.ROOT + ROUTES.SHOWS} className="close-match">
					<span className="text">Close <strong>Highlight</strong></span><span className="times">&times;</span>
				</NavLink>}
				<iframe className="video-js vjs-theme-city" src={MatchPage.HighlightMatchUrl} allow="autoplay"></iframe>
				<ChatBox roomId={`match-${AppSettings.Sport}`} />
			</div>
		</CSSTransition>
	);
}

export default memo(HighlightMatch);