/* eslint-disable space-before-function-paren */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { CSSProperties, memo, useCallback, useMemo, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import useBadges from '../../hooks/useBadges';
import useGlobalVariables from '../../hooks/useGlobalVariables';
import useStats from '../../hooks/useStats';
import useBrands from '../../hooks/useBrands';
import useXP from '../../hooks/useXP';
import numberWithCommas from '../../utils/numberWithCommas';
import Chevron from '../icons/Chevron';
import Avatar from '../ui/Avatar';
import Badge from '../ui/Badge';
import LevelBadge from '../ui/LevelBadge';
import LinedTitle, { LinedTitleTypes } from '../ui/LinedTitle';
import { useLogout } from '../../hooks/useLogout';
import Missions from '../Missions';
import usePlayer from '../../hooks/usePlayer';
import usePolls from '../../hooks/usePolls';
import LoadingSpinner from '../icons/LoadingSpinner';
import Loading from '../ui/Loading';
import CurrencyFloater from '../ui/CurrencyFloater';
import useCityBackground from '../../hooks/useCityBackground';
import useInventory from '../../hooks/useInventory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/sharp-solid-svg-icons';

function Product({
	item,
}:{
	item?: IXRInventoryItemParsedData;
}) {
	return (item !== undefined) ? (
		<div className="item">
			<img src={item.publicData.Image as string} alt={item.playfab.DisplayName} />
			<div className="item-name">{item.playfab.DisplayName}</div>
		</div>
	) : (
		<div className="item blank-item">
			<div className="add-product-img">
				<FontAwesomeIcon icon={faPlus} />
			</div>
			<div className="item-name">Add product</div>
		</div>
	);
}

const CITY_POLL_ID = 'p-6534356ea6941';

function Profile() {
	const {
		DisplayName,
		currencies: { CO },
	} = usePlayer();

	const page = useRef<HTMLDivElement>(null);
	const content = useRef<HTMLDivElement>(null);

	const { Logout: OnClickLogout } = useLogout();

	const { ProfilePage, isLoaded } = useGlobalVariables();

	const {
		PollTitle,
	} = ProfilePage || {};

	const {
		brands,
		setFavoriteBrand,
		hasFavoriteBrand,
	} = useBrands();

	const {
		xp,
		nextLevelXp,
	} = useXP();

	const {
		matches_watched,
		minutes_watched,
		achievements_unlocked,
		viewer_type,
	} = useStats();

	const badges = useBadges();

	useCityBackground();

	const {
		polls,
		answerPoll,
		answers,
	} = usePolls();

	const poll = polls.find(p => p?.poll?.itemId === CITY_POLL_ID);

	const [isLoading, setIsLoading] = useState(false);

	const answer = answers ? answers?.[poll?.instanceId] : null;

	const onAnswerPoll = useCallback(async (choice:string) => {
		setIsLoading(true);
		await answerPoll(poll, choice);
		setIsLoading(false);
	}, [poll]);
	
	const { userProducts: inventoryProducts } = useInventory();

	const userProducts = useMemo(() => {
		if (inventoryProducts.length === 0) {
			return [...Array(3)];
		}

		const emptySlots = inventoryProducts.length % 3;
		return [...inventoryProducts, ...Array(emptySlots)];
	}, [inventoryProducts]);

	if (!isLoaded) return (
		<Loading isLoading={isLoaded} />
	);

	return (
		<CSSTransition nodeRef={page} key="profile" timeout={1200} in={true} appear>
			<div className="page profile" ref={page}>
				<div className="page-content">
					<CSSTransition nodeRef={content} in={Boolean(poll)} timeout={1200} mountOnEnter unmountOnExit appear>
						<div className="content" ref={content}>
							<CSSTransition in={Boolean(poll) && !poll?.hasAnswered} timeout={1200} mountOnEnter unmountOnExit appear>
								<div className="poll-ctn">
									<p className="above-poll-title animated" style={{ '--i': 4 } as CSSProperties}>{PollTitle}</p>
									<div className={`poll ${answer ? 'answered' : ''}`} key={poll?.instanceId}>
										{poll && <>
											<div className="question">{poll.poll.question}</div>
											<div className="ctn">
												<CSSTransition in={!answer && !isLoading} timeout={1200} appear>
													<div className="choices">
														{poll.poll.choices.map(choice => (
															<div className={`choice ${choice?.id === answer?.choice ? 'selected' : ''}`} key={choice?.id} onClick={() => onAnswerPoll(choice?.id)}>
																<div className="text">{choice?.label}</div>
															</div>
														))}
													</div>
												</CSSTransition>
												<CSSTransition in={isLoading} timeout={1200} appear>
													<div className="spinner"><LoadingSpinner /></div>
												</CSSTransition>
											</div>
										</>}
									</div>
								</div>
							</CSSTransition>
						</div>
					</CSSTransition>
				</div>
				<div className="sidebar">
					<LinedTitle text='Your <strong>profile</strong>' size={LinedTitleTypes.LARGE} caps />
					<div onClick={OnClickLogout} className="logout-button">Log Out</div>
					<div className="player-profile">
						<div className="rounded player-card">
							<div className="content">
								<Avatar />
								<div className="side">
									<div className="player-name">{DisplayName} <LevelBadge /></div>
									<div className="xp"><span className="current">XP {numberWithCommas(xp)}</span> / {numberWithCommas(nextLevelXp)}</div>
									<div className="role">VIP Member</div>
								</div>
							</div>
							<Chevron />
						</div>
						<div className="rounded wallet">
							<CurrencyFloater />
							<div className="amount">{numberWithCommas(CO)}</div>
							<div className="currency">Coins</div>
						</div>
					</div>
					<div className="rounded items-box">
						<LinedTitle text="What's your <strong>favorite brand?</strong>" caps />
						<div className="items-list brands">
							{brands.map(brand => (
								<div className={`item ${brand.data.IsFavorite ? 'favorite' : ''} brand-${brand.data.BrandId}`} onClick={() => setFavoriteBrand(brand.data.BrandId as number)} key={brand.itemId}>
									<img src={brand.data.Logo as string} alt={brand.playfab.DisplayName} />
									<div className="item-name">{brand.playfab.DisplayName}</div>
								</div>
							))}
						</div>
						{!hasFavoriteBrand && <div className="no-brand">Select your favorite brand by clicking on one of the brands logo.</div>}
					</div>
					<div className="rounded items-box">
						<LinedTitle text="My <strong>Products</strong>" caps />
						<div className="items-list products">
							<TransitionGroup component={null}>
								{userProducts.map((item: IXRInventoryItemParsedData | undefined, index) => (
									<CSSTransition timeout={{ enter: 500, exit: 0 }} key={item?.id ?? index}>
										<Product item={item} />
									</CSSTransition>
								))}
							</TransitionGroup>
						</div>
					</div>
					<LinedTitle className='missions-title' text='Your <strong>Weekly Challenges</strong>' caps />
					<Missions />
					<LinedTitle className='achievements-title' text='Your <strong>Achievements</strong>' caps />
					<div className="rounded stats-panel">
						<LinedTitle className='stats-title' text='Your <strong>Statistics</strong>' caps />
						<div className="stats-grid">
							<div className="stat">
								<div className="name"><strong>Matches</strong> watched</div>
								<div className="value">{matches_watched}</div>
							</div>
							<div className="stat">
								<div className="name"><strong>Minutes</strong> watched</div>
								<div className="value">{minutes_watched}</div>
							</div>
							<div className="stat">
								<div className="name"><strong>Achievements</strong> unlocked</div>
								<div className="value">{achievements_unlocked}</div>
							</div>
							<div className="stat">
								<div className="name"><strong>Viewer</strong> Type</div>
								<div className="value">{viewer_type}</div>
							</div>
						</div>
					</div>
					<div className="badges">
						{badges.map(badge => (
							<Badge key={badge.itemId} item={badge} />
						))}
					</div>
				</div>
			</div>
		</CSSTransition>
	);
}

export default memo(Profile);