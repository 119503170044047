
export const GLOBAL_NAMESPACE = 'xrsportdemo';

export const APP_VERSION = '2023-10-23.1';

declare global {
	interface Window {
		APP_CONFIG: {
			BASE_PATH: string;
			AS_STACK_APP_ID: string;
			PLAYFAB_APP_ID: string;
			REALTIME_API_KEY: string;
		}
	}
}

export const XR_CONFIG = {
	apiUrl: 'https://hive-demo.xrserver.com',
	appId: window.APP_CONFIG.AS_STACK_APP_ID,
} as IXRConfig;

export const PLAYFAB_CONFIG = {
	appId: window.APP_CONFIG.PLAYFAB_APP_ID,
} as IPlayFabConfig;


export const REALTIME_CONFIG = {
	apiKey: window.APP_CONFIG.REALTIME_API_KEY,
	apiUrl: 'http://realtime-demo.xrserver.com',
	eventSourceUrl: 'https://hub.xrserver.com/.well-known/mercure',
} as IRealtimeConfig;

export const API_CONFIG = {
	xr: XR_CONFIG,
	playfab: PLAYFAB_CONFIG,
	realtime: REALTIME_CONFIG,
} as IApiConfig;


export const ENDPOINTS = {
	REALTIME_EVENT_SOURCE: 'https://hub.xrserver.com/.well-known/mercure',
};

export const DEFAULT_LANG = 'en';

export const CDN_BASE = '';

export const DEFAULT_ERROR_MESSAGE = 'An error has occurred';

export const BASE_PATH = window.APP_CONFIG.BASE_PATH;

export const ROUTES = {
	ROOT: '/',
	PROFILE: 'profile',
	LOGIN: 'login',
	SHOWS: 'shows',
	SHOW: 'shows/:id',
	MATCH: 'match',
	HIGHLIGHT_MATCH: 'highlight-match',
	STORE: 'store',
	REWARD_TRACKS: 'reward-tracks',
	MATCH_PREDICTION: 'match-prediction',
	MATCH_PREDICTION_RESOLVE: 'match-prediction-resolve',

	MINI_GAMES: 'mini-games',
	MEMORY: 'mini-games/memory',
};

export const EXCLUDED_LOGGER_ACTIONS = [
	'env/setBackground',
];

export const POLL_RATES = {
	SEND_HEARTBEAT: 1000 * 60,
};

export const ITEM_CLASSES = {
	OVERRIDE: 'Override',
	TEAM: 'Team',
	BRAND: 'Brand',
	BADGE: 'Badge',
	SHOP_PRODUCT: 'Shop Product',
	MEMORY_GAME_THEME: 'Memory Game Theme',
	MINESWEEPER_THEME: 'Minesweeper Theme',
};

export const MISSIONS_TYPES = {
	CHALLENGE: 'Challenge',
	REWARD_TRACK: 'Reward Track',
};

export enum QuestionTypes {
	Multiple = 'multiple',
	MultipleImage = 'multiple-image',
	Inputs = 'inputs',
}

export const PREDICTION_MATCH_ID = 'custom_match_id';
