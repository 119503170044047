import React, { memo, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../redux/config/store';

const BGImage = memo(({ src = '', current = false }:{ src:string, current: boolean }) => {
	const classes:string[] = [];

	const [loaded, setLoaded] = useState(false);
	const ref = useRef<HTMLImageElement>();

	useEffect(() => {
		if (ref.current) {
			if (ref.current.complete) setLoaded(true);
			else ref.current.onload = () => setLoaded(true);
		}
	}, [ref.current]);

	if (current && loaded) classes.push('current');

	return (
		<img ref={ref} className={classes.join(' ')} src={src} alt="" />
	);
});

export default function PageBackground() {
	const src = useAppSelector((state) => state.env.background || '');
	const [currentImage, setCurrentImage] = useState<string>(src);

	const [images, setImages] = useState<string[]>([src]);

	useEffect(() => {
		setCurrentImage(src);
		setImages(s => {
			if (s.includes(src)) return s;
			return [...s, src];
		});
	}, [src]);

	return (
		<div className="page-background">
			{images && images.map((image, i) => (
				image && <BGImage key={i} src={image} current={image === currentImage} />
			))}
		</div>
	);
}