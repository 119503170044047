import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IRootState, useAppDispatch } from '../redux/config/store';
import { getAppData } from '../redux/env';

export default function useAppData() {
	const dispatch = useAppDispatch();

	const AppData = useSelector((state: IRootState) => state.env.App);

	useEffect(() => {
		if (!AppData) {
			dispatch(getAppData());
		}
	}, [AppData]);

	return {
		...AppData,
	};
}