import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const rfid = createSlice({
	name: 'rfid',
	reducers: {
		addRFIDTag: (state, action:PayloadAction<{ rfid: string }>) => {
			if (state.latestTag !== action.payload.rfid) {
				state.latestTag = action.payload.rfid;
			}
		},
		clearRFIDTag: (state) => {
			state.latestTag = '';
		},
	},
	initialState: {
		latestTag: '',
	},
});

export default rfid;

export const {
	addRFIDTag,
	clearRFIDTag,
} = rfid.actions;