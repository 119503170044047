import * as React from 'react';
import useCatalog from '../../hooks/useCatalog';
import { IPlayerStatistics } from '../../redux/statistics';

type RewardTrackItemData = IXRInventoryItemParsedData & { count?: number };

function Item({ item, isComplete, isClaimed = true }:{ item: RewardTrackItemData, isComplete: boolean, isClaimed?: boolean }) {
	const classes = ['reward-tier-item'];
	if (!isComplete) classes.push('locked');
	else if (!isClaimed) classes.push('claimable');
	else classes.push('claimed');

	const { VirtualCurrencyContents } = item.playfab.Container;

	const getDisplayName = () => {
		if (VirtualCurrencyContents) {
			const [currencyKey, currencyQuantity] = Object.entries(VirtualCurrencyContents)[0];
			return `+${currencyQuantity} ${currencyKey}`;
		}
		return item.playfab.DisplayName;
	};

	const getImage = () => {
		if (VirtualCurrencyContents) {
			const currencyQuantity = Object.values(VirtualCurrencyContents)[0];
			return (
				<svg className="currency-reward-icon" width="119" height="118" viewBox="0 0 119 118" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="59.8971" cy="59" r="58.0992" stroke="white" strokeWidth="1.80153"/>
					<text fill="#fff" x="60" y="76" fontSize="50px"  fontFamily="Inter, sans-serif" fontWeight="300" textAnchor="middle">{currencyQuantity}</text>
				</svg>
			);
		}
		if (item.data.image) {
			return <img src={item.data.image as string} alt={getDisplayName()} />;
		}
		return null;
	};

	return (
		<div className={classes.join(' ')}>
			<div className="name">{getDisplayName()}</div>
			{item.count > 1 && <div className="count">{` x${item.count}`}</div>}
			{getImage()}
			<div className="item-claimed">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 27 24"><path fill="#fff" d="M18.767 11.45c.148.606.233 1.236.233 1.884 0 4.412-3.588 8-8 8s-8-3.588-8-8c0-4.413 3.588-8 8-8 1.784 0 3.427.593 4.759 1.583l1.905-1.904A10.6 10.6 0 0 0 11 2.667C5.108 2.667.333 7.443.333 13.334.333 19.224 5.108 24 11 24c5.89 0 10.667-4.776 10.667-10.666 0-1.416-.282-2.763-.783-4l-2.117 2.117Z"/><path fill="#fff" d="M11 13.563 7.228 9.79l-1.885 1.885L11 17.333 26.084 2.248 24.199.361l-13.2 13.202Z"/></svg>
			</div>
			<div className="item-locked">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 24"><path fill="#fff" d="M16.667 24h-16V10.667h16V24ZM15.333 9.333h-2.666V6.667c0-2.206-1.795-4-4-4-2.206 0-4 1.794-4 4v2.666H2V6.667A6.674 6.674 0 0 1 8.667 0a6.674 6.674 0 0 1 6.666 6.667v2.666Z"/></svg>
			</div>
			<div className="item-claimable"></div>
		</div>
	);
}

type RewardTrackItemProps = {
	item: IXRInventoryItemParsedData,
	isComplete: boolean,
	isClaimed?: boolean,
	showTracker?: boolean,
	thresholds?: number[],
	statName?: keyof IPlayerStatistics,
};

function mergeItems(itemIds:string[], catalog:IXRInventoryItemParsedData[]) {
	return Object.values((itemIds || []).reduce((acc, itemId) => {
		const item = catalog.find(i => i.itemId === itemId);
		if (!acc[item.itemId]) {
			acc[item.itemId] = { item, count: 1 };
		} else {
			acc[item.itemId].count += 1;
		}
		return acc;
	}, {} as Record<string, { item: IXRInventoryItemParsedData, count: number }>)).map((item) => {
		const i:RewardTrackItemData = { ...item.item };
		i.count = item.count;
		return i;
	});
}

function RewardTrackItem({ item, isComplete }: RewardTrackItemProps) {
	const catalog = useCatalog();

	let els;
	if (item.playfab?.Container?.ItemContents) {
		els = mergeItems(item.playfab.Container.ItemContents, catalog.items).map((i) => <Item item={i} isComplete={isComplete} key={i.id} />);
	} else {
		els = [<Item item={item} isComplete={isComplete} key={item.id} />];
	}

	return (
		<>
			{els}
		</>
	);
}

export default RewardTrackItem;