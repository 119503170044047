import React, { memo } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { ROUTES } from './Constants';
import '../css/index.scss';
import Layout from './components/Layout';
import usePlayFab from './hooks/usePlayFab';
import Login from './components/routes/Login';
import useRealtime from './hooks/useRealtime';
import Media from './components/routes/Media';
import Match from './components/routes/Match';
import Profile from './components/routes/Profile';
import Store from './components/routes/Store';
import useInventoryExpiration from './hooks/useInventoryExpiration';
import useHeartbeat from './hooks/useHeartbeat';
import useRouteEvents from './hooks/useRouteEvents';
import Notifications from './components/Notifications';
import Debug from './components/Debug';
import HighlightMatch from './components/routes/HighlightMatch';
import RFIDLogin from './components/RFIDLogin';
import RewardTracks from './components/routes/RewardTracks';
import PageBackground from './components/ui/PageBackground';
import useXRStore from './hooks/useXRStore';
import MiniGames from './components/routes/MiniGames';
import Memory from './components/routes/mini-games/Memory';
import Show from './components/routes/Show';

function App() {
	const {
		playerId,
	} = usePlayFab();

	useRealtime();
	useInventoryExpiration();
	useHeartbeat();
	useRouteEvents();

	useXRStore();

	const isLogin = useMatch(ROUTES.ROOT + ROUTES.LOGIN);

	return (
		<>
			{!isLogin && !playerId && <Navigate to={ROUTES.LOGIN} replace={true} />}
			<Debug />
			<RFIDLogin />
			<Routes>
				<Route path={ROUTES.ROOT} element={<Layout />}>
					<Route index element={<Profile />} />
					<Route path={ROUTES.LOGIN} element={<Login />} />
					<Route path={ROUTES.SHOWS} element={<Media />} />
					<Route path={ROUTES.SHOW} element={<Show />} />
					<Route path={ROUTES.MATCH} element={<Match />} />
					<Route path={ROUTES.HIGHLIGHT_MATCH} element={<HighlightMatch />} />
					<Route path={ROUTES.PROFILE} element={<Navigate to={ROUTES.ROOT} />} />
					<Route path={ROUTES.STORE} element={<Store />} />
					<Route path={ROUTES.REWARD_TRACKS} element={<RewardTracks />} />
					<Route path={ROUTES.MINI_GAMES} element={<MiniGames />} />
					<Route path={ROUTES.MEMORY} element={<Memory />} />
				</Route>
			</Routes>
			<Notifications />
			<PageBackground />
		</>
	);
}

export default memo(App);
