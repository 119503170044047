import React from 'react';
import './rebates.scss';

const Rebates = ({ moves }: { moves: number }) => {
	return (
		<div className="row rebates">
			<div className="bracket left" />
			<div className={'rebate' + (moves < 7 ? ' active' : '')}>
				<div>30%</div>
				<div className="text">REBATES</div>
			</div>
			<div className="bracket right" />
			<div className="bracket left" />
			<div className={'rebate' + (moves < 15 ? ' active' : '')}>
				<div>20%</div>
				<div className="text">REBATES</div>
			</div>
			<div className="bracket right" />
			<div className="bracket left" />
			<div className={'rebate' + (moves <= 21 ? ' active' : '')}>
				<div>10%</div>
				<div className="text">REBATES</div>
			</div>
			<div className="bracket right" />
		</div>
	);
};

export default Rebates;
