import { useMemo } from 'react';
import useGlobalVariables from './useGlobalVariables';

export default function useShows() {
	const { ShowList } = useGlobalVariables();

	const favorites = useMemo(() => {
		return ShowList?.showList?.filter(show => show.isFavorite) ?? [];
	}, [ShowList]);

	const getShowById = (showId: string) => {
		return ShowList?.showList?.find(show => show.showId === showId);
	};

	return {
		showList: ShowList?.showList ?? [],
		favorites,
		getShowById,
	};
}
